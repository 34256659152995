import gql from "graphql-tag";

export const ACCOUNTS_QUERY = gql`
  query v3_customer_accounts($filter: V3_Customer_AccountFilter, $limit: Int, $skip: Int) {
    v3_Customer_Accounts(limit: $limit, skip: $skip, where: $filter) {
      id
      nameValue
      domainsValue
      salesforceIdsValue
      logoValue
      industriesValue
      salesforceLastActivityDateValue
      employeeCountRangesValue
      lastContactedAt
      owner {
        fullName
      }
      status
    },
    _v3_Customer_AccountsMeta(where: $filter) {
      count
    }
  }
`;