import gql from 'graphql-tag';

export const ACCOUNTSELECTORSTATISTICSQUERYSTRING = gql`
  query accountSelectorStats(
    $customerId: ID!
    $industries: [String]
    $selectorType: String
    $days: Int
  ) {
    accountSelectorStats(
      customerId: $customerId
      industries: $industries
      selectorType: $selectorType
      days: $days
    ) {
      totalAccounts
      matchedAccounts
      totalContacts
      matchedContacts
    }
  }
`;
