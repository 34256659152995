import React from 'react';
import KoncertLogo from 'apps/cadence/src/components/Common/KoncertLogo';
import ErrorLinks from 'apps/cadence/src/components/Common/ErrorLinks';

const Error500 = (props) => (
  <div className="d-flex flex-column justify-content-center h-100">
    <div className="text-center mb-4">
      <div className="mb-3">
        <KoncertLogo />
      </div>
      <div className="text-lg mb-3">
        <i className="fa fa-wrench text-muted mr-2"></i>
        500
      </div>
      <p className="lead m-0">Oh! Something went wrong :(</p>
      <p>Don't worry, we're now checking this.</p>
      <p>
        In the meantime, please try one of those links below or come back in a
        moment
      </p>
    </div>
    <ErrorLinks props={props} gotoApp={true}/>
  </div>
);

export default Error500;
