import gql from 'graphql-tag';

export const CREATE_BASIC_STORY = gql`
  mutation CreateBasicStory(
    $customerId: String!
    $name: String!
    $priority: Int
    $accountSelectorId: String!
    $contactSelectorId: String!
    $rulesOfEngagementId: String!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int
    $sendingWindowDayEnd: Int
    $sendingWindowHourStart: Int
    $sendingWindowHourEnd: Int
  ) {
    createV3_Customer_Stories_Basic(
      data: {
        customerId: $customerId
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      id
    }
  }
`;

export const CREATE_SIMPLE_STORY = gql`
  mutation CreateSimpleStory(
    $customerId: String!
    $name: String!
    $priority: Int
    $accountSelectorId: String!
    $contactSelectorId: String!
    $rulesOfEngagementId: String!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int
    $sendingWindowDayEnd: Int
    $sendingWindowHourStart: Int
    $sendingWindowHourEnd: Int
  ) {
    createV3_Customer_Stories_Simple(
      data: {
        customerId: $customerId
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      id
    }
  }
`;

export const CREATE_CLOSED_LOST_STORY = gql`
  mutation CreateClosedLostStory(
    $customerId: String!
    $name: String!
    $priority: Int
    $accountSelectorId: String!
    $contactSelectorId: String!
    $rulesOfEngagementId: String!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int
    $sendingWindowDayEnd: Int
    $sendingWindowHourStart: Int
    $sendingWindowHourEnd: Int
  ) {
    createV3_Customer_Stories_ClosedLost(
      data: {
        customerId: $customerId
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      id
    }
  }
`;

export const CREATE_OPEN_OPPS_NO_ACTIVITY_STORY = gql`
  mutation CreateOpenOppsNoActivityStory(
    $customerId: String!
    $name: String!
    $priority: Int
    $accountSelectorId: String!
    $contactSelectorId: String!
    $rulesOfEngagementId: String!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int
    $sendingWindowDayEnd: Int
    $sendingWindowHourStart: Int
    $sendingWindowHourEnd: Int
  ) {
    createV3_Customer_Stories_OpenOppsNoActivity(
      data: {
        customerId: $customerId
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      id
    }
  }
`;

export const CREATE_OLD_CUSTOMER_NEW_JOB_STORY = gql`
  mutation CreateOldCustomerNewJobStory(
    $customerId: String!
    $name: String!
    $priority: Int
    $accountSelectorId: String!
    $contactSelectorId: String!
    $rulesOfEngagementId: String!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int
    $sendingWindowDayEnd: Int
    $sendingWindowHourStart: Int
    $sendingWindowHourEnd: Int
  ) {
    createV3_Customer_Stories_OldCustomerNewJob(
      data: {
        customerId: $customerId
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      id
    }
  }
`;

export const CREATE_DEMO_STORY = gql`
  mutation CreateDemoStory(
    $customerId: String!
    $name: String!
    $priority: Int
    $accountSelectorId: String!
    $contactSelectorId: String!
    $rulesOfEngagementId: String!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int
    $sendingWindowDayEnd: Int
    $sendingWindowHourStart: Int
    $sendingWindowHourEnd: Int
  ) {
    createV3_Customer_Stories_Demo(
      data: {
        customerId: $customerId
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      id
    }
  }
`;
