import gql from 'graphql-tag';

export const PLOTPOINT_CATEGORIES_QUERY = gql`
  query v3_Customer_StoryComponents_PlotPointCategories($customerId: ID) {
    v3_Customer_StoryComponents_PlotPointCategories(
      where: { customerId_in: [$customerId, null] }
    ) {
      id
      name
    }
  }
`;
