import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Table from './table/Table';
import TableActions from './table/TableActions';
import { Column, LinkColumn } from './table/columns';
import { levelWord } from '../enums/EventLogLevelWord';
import moment from 'moment';

class EventLogs extends Component {
  static propTypes = {
    eventLogs: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      rows: this.buildTableRows(props.eventLogs),
      filter: {},
    };
  }

  static levelClass = ['success', '', 'warning', 'danger', 'danger'];
  static defaultColumns = ['when', 'message', 'event', 'customer', 'user'];

  columns = {
    when: () => <Column key="when" name="When" />,
    timestamp: () => <Column key="timestamp" name="Timestamp" />,
    id: () => <Column key="id" name="Id" />,
    message: () => (
      <LinkColumn key="message" linkUrlProp="eventLogUrl" name="Message" />
    ),
    event: () => <Column key="event" name="Event" allowFilter={true} />,
    location: () => (
      <Column key="location" name="Location" allowFilter={true} />
    ),
    customer: () => (
      <Column key="customer" name="Customer" allowFilter={true} />
    ),
    user: () => <Column key="user" name="User" allowFilter={true} />,
  };

  componentDidUpdate(prevProps) {
    const { eventLogs } = this.props;

    if (prevProps.eventLogs === eventLogs) return;
    this.setState({
      rows: this.buildTableRows(eventLogs),
    });
  }

  buildTableRows(eventLogs) {
    if (!eventLogs || !eventLogs.length) return [];
    return eventLogs.map((log) => {
      const row = Object.assign({}, log);
      row.when = moment(log.createdAt).fromNow();
      row.timestamp = log.createdAt;
      row.message = `${levelWord[log.level]}: ${log.message} `;
      row.eventLogUrl = `#/admin/eventlogs/${row.id}`;
      row.location = `${log.path}:${log.lineno}:${log.label}`;
      row.customer = log.customer && log.customer.name;
      row.user = log.user && log.user.email;
      row.props = { rowcolor: EventLogs.levelClass[log.level] };

      return row;
    });
  }

  handlePageSelect = (page) => {
    this.setState({ page });
    if (this.props.paginateEventLogs) {
      this.props.paginateEventLogs(page);
    }
  };

  handleFilterChange = (value, filterName) => {
    var filter = { ...this.state.filter };
    const { onFilter } = this.props;
    if (value)
      filter[filterName] = filterName === 'user' ? { email: value } : value;
    else delete filter[filterName];

    this.setState({ filter });

    if (onFilter)
      onFilter({
        ...filter,
      });
  };

  getColumns(columnsList) {
    return (columnsList || EventLogs.defaultColumns).map(
      (colName) => this.columns[colName]
    );
  }

  render() {
    const columns = this.getColumns(this.props.columns);
    return (
      <React.Fragment>
        <Table
          style={this.props.style}
          className={this.props.fixedheight ? 'obw-table-fixed-height' : ''}
          loading={this.props.loading}
          rows={this.state.rows}
          emptyText="There are not event logs"
          selectable={this.props.selectable}
          showSearch={this.props.showSearch}
          onFilter={this.handleFilterChange}
        >
          {columns.map((col) => {
            return col();
          })}
          <TableActions>
            {/* <Paginator page={toInteger(this.props.page) || 1} total={this.props.eventLogsCount} perPage={this.props.perPage} onPageSelect={this.handlePageSelect}/> */}
            {this.props.children}
          </TableActions>
        </Table>
      </React.Fragment>
    );
  }
}

export default EventLogs;
