import gql from 'graphql-tag';

export const CONTACTSELECTORSTATISTICSQUERYSTRING = gql`
  query contactSelectorStats($customerId: ID!, $titles: [String]) {
    contactSelectorStats(customerId: $customerId, titles: $titles) {
      totalContacts
      matchedContacts
    }
  }
`;
