import gql from 'graphql-tag';

export const SYSTEMJOURNALAGGREGATION = gql`
  query systemJournalAggregation(
    $customerId: ID!
    $format: String = "day"
    $startDate: DateTime
    $endDate: DateTime
  ) {
    systemJournalAggregation(
      customerId: $customerId
      format: $format
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      startDate
      endDate
      data
    }
  }
`;

export default SYSTEMJOURNALAGGREGATION;
