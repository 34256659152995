import gql from 'graphql-tag';

export const CREATE_PLOTPOINT = gql`
  mutation CreatePlotPoint(
    $storyId: String!
    $messageId: String!
    $name: String!
    $position: Int
    $plotPointCategoryId: String!
  ) {
    createV3_Customer_StoryComponents_PlotPoint(
      data: {
        name: $name
        storyId: $storyId
        messageId: $messageId
        position: $position
        plotPointCategoryId: $plotPointCategoryId
      }
    ) {
      id
    }
  }
`;
