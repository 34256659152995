import gql from 'graphql-tag';

export const MARK_STORY_CONTACT_AS_SEEN = gql`
  mutation MarkStoryContactAsSeen($id: ID!, $lastViewedAt: DateTime) {
    updateV3_Customer_StoryContact(
      id: $id
      data: { lastViewedAt: $lastViewedAt }
    ) {
      id
      lastViewedAt
    }
  }
`;
