import React from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { JOB_QUERY, EVENT_LOGS_QUERY } from '@koncert/graphql';
import { JobDetails } from '../JobDetails';
import { connect } from 'react-redux';
import EventLogs from '../EventLogs';
import { useQuery } from '@apollo/react-hooks';

const JobPage = ({ match, history, customerId }) => {
  const { data, loading } = useQuery(JOB_QUERY, {
    variables: { id: match.params.id },
    skip: !customerId,
  });

  const { data: eventData, loading: eventLoading } = useQuery(
    EVENT_LOGS_QUERY,
    {
      variables: { filterQuery: { jobId: match.params.id } },
      skip: !customerId,
    }
  );

  if (loading || eventLoading) return <i className="fa fa-spinner fa-spin"></i>;

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} md={6}>
          <Card>
            <CardHeader>
              <CardTitle>Job Details</CardTitle>
            </CardHeader>
            <CardBody>
              <JobDetails job={data.job} />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <CardHeader>
              <CardTitle>Child Jobs</CardTitle>
            </CardHeader>
            <CardBody>
              {/* <JobsList jobs={jobs} total={jobsCount.count} /> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader>
              <CardTitle>Event logs</CardTitle>
            </CardHeader>
            <CardBody>
              <EventLogs jobId={data.job.id} eventLogs={eventData.eventLogs} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  customerId: state.customer.id,
  customerName: state.customer.name,
});
export const ConnectedJobPage = connect(mapStateToProps)(JobPage);

export default ConnectedJobPage;
