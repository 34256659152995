import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import RoutedElementEditor from './RoutedElementEditor';
import ReactHtmlParser from 'react-html-parser';
import {
  Card,
  CardBody,
  CardHeader,
  TabContent,
  TabPane,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  PopoverHeader,
  UncontrolledPopover,
  PopoverBody,
  Badge,
} from 'reactstrap';
import moment from 'moment';

import { Avatar } from '@koncert/shared-components';

const EmailMessages = ({
  row,
  elements,
  elementToMessageLookup,
  emailContent,
  storyId,
  customerId,
  currentUser,
  refreshMessages,
  userLoading,
}) => {
  const [elementEditorParams, setElementEditorParams] = useState(null);
  const [activeTab, setActiveTab] = useState('0');
  const storyContact = row.original;

  if (!elementToMessageLookup) return <i className="fa fa-spinner fa-spin"></i>;

  const sortedElements = elementToMessageLookup.map((messageElements) => {
    return elements
      .filter((e) => messageElements.indexOf(e.id) > -1)
      .sort((a, b) => {
        const ppA = a.plotPointAsAdditional ?? a.plotPointAsDefault;
        const ppB = b.plotPointAsAdditional ?? b.plotPointAsDefault;
        if (
          ppA.position < ppB.position ||
          (ppA.position === ppB.position && ppA.createdAt < ppB.createdAt)
        ) {
          return -1;
        }
        if (
          ppA.position > ppB.position ||
          (ppA.position === ppB.position && ppA.createdAt > ppB.createdAt)
        ) {
          return 1;
        }
        return 0;
      });
  });

  const messageKeys = Object.keys(emailContent);

  return (
    <>
      <Card className="card-default">
        <CardHeader>
          {storyContact.contact.givenNameValue}{' '}
          {storyContact.contact.familyNameValue},{' '}
          {storyContact.contact.position.title}
          <div className="card-tool float-right">
            {(storyContact.status === 'New' ||
              storyContact.status === 'Paused') && (
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  refreshMessages();
                }}
                className="btn btn-outline"
              >
                <i className="fa fa-sync"></i>
              </a>
            )}
            <em
              className="fa fa-times"
              {...row.getToggleRowExpandedProps()}
            ></em>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs={12} lg={4}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6 text-center">
                      <img
                        className="circle thumb96"
                        src={Avatar}
                        alt="Avatar"
                      />
                    </div>
                    <div className="col-6">
                      <h3 className="mt-0">
                        {storyContact.contact.givenNameValue}{' '}
                        {storyContact.contact.familyNameValue}
                      </h3>
                      <ul className="list-unstyled mb-0">
                        <li className="mb-1">
                          <em className="fas fa-building fa-fw mr-1"></em>
                          {storyContact.account.nameValue}{' '}
                          <a
                            href={`${currentUser.salesforceUrl}/${storyContact.account.salesforceIdsValue}`}
                            target="_new"
                          >
                            <small>
                              <i className="ml-1 fab fa-salesforce text-primary"></i>
                              <i className="ml-1 fa fa-external-link-alt"></i>
                            </small>
                          </a>
                        </li>
                        <li className="mb-1">
                          <em className="fas fa-user-tag fa-fw mr-1"></em>
                          {storyContact.contact.position.title}
                        </li>
                        <li className="mb-1">
                          <em className="fa fa-envelope fa-fw mr-1"></em>
                          {storyContact.contact.position.email}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Row className="pt-2">
                    <Col>
                      {/* <pre>{JSON.stringify(storyContact, null, 2)}</pre> */}
                      <Table className="b">
                        <tbody>
                          <tr>
                            <td>Content Generated</td>
                            <td>{moment(storyContact.updatedAt).fromNow()}</td>
                          </tr>
                          <tr className="w-100">
                            <td>Last Attempt</td>
                            <td>
                              {!storyContact.contact.lastApproachedAt && '-'}
                              {storyContact.contact.lastApproachedAt &&
                                moment(
                                  storyContact.contact.lastApproachedAt
                                ).fromNow()}
                            </td>
                          </tr>
                          <tr className="w-100">
                            <td>Last Contacted</td>
                            <td>
                              {!storyContact.contact.lastContactedAt && '-'}
                              {storyContact.contact.lastContactedAt &&
                                moment(
                                  storyContact.contact.lastContactedAt
                                ).fromNow()}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
                <div className="card-body bg-light">
                  <Row className="text-center">
                    <Col>
                      <p className="m-0 h3">
                        {storyContact.personalizationScore} <small>/1000</small>
                      </p>
                      <p className="m-0 text-muted">Personalization Score</p>
                    </Col>
                    <Col>
                      <a
                        href={`${currentUser.salesforceUrl}/${storyContact.contact.salesforceIdsValue}`}
                        target="_new"
                      >
                        <p className="m-0 h3">
                          <i className="ml-1 fab fa-salesforce text-primary"></i>
                        </p>
                        <p className="m-0 text-muted">Open in Salesforce</p>
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col>
              <div role="tabpanel">
                {/* Nav tabs */}
                <Nav tabs>
                  {emailContent &&
                    messageKeys.map((key, index) => (
                      <NavItem key={key}>
                        <NavLink
                          className={
                            activeTab === String(index) ? 'active' : ''
                          }
                          onClick={() => {
                            setActiveTab(String(index));
                          }}
                        >
                          {key}
                        </NavLink>
                      </NavItem>
                    ))}
                </Nav>
                {/* Tab panes */}
                <TabContent activeTab={activeTab}>
                  {messageKeys.map((key, index) => (
                    <TabPane tabId={String(index)} key={key}>
                      {emailContent[key].map((sentence, i) => {
                        if (sortedElements.length === 0) {
                          return <div>{ReactHtmlParser(sentence)}</div>;
                        }
                        const element = sortedElements[index][i];
                        if (element === undefined) {
                          console.log(sentence);
                          return null;
                        }
                        const pp =
                          element.plotPointAsAdditional ??
                          element.plotPointAsDefault;
                        const contentQualityClassName =
                          element.triggerType === 'Static'
                            ? 'neutral-quality'
                            : 'positive-quality';
                        return (
                          <div
                            key={i}
                            className={
                              'message-plot-point ' + (i === 0 ? 'subject' : '')
                            }
                          >
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target={`plot-point-${index}-${i}`}
                            >
                              <PopoverHeader className="plot-point-popover-header">
                                {
                                  (
                                    element.plotPointAsAdditional ||
                                    element.plotPointAsDefault
                                  ).name
                                }{' '}
                                Plot Point
                              </PopoverHeader>
                              <PopoverBody>
                                <p>
                                  <strong>
                                    {element.triggerType.replace(
                                      /([a-z])([A-Z])/g,
                                      '$1 $2'
                                    )}{' '}
                                    (
                                    {element.plotPointAsDefault
                                      ? 'Default'
                                      : 'Variant'}
                                    )
                                  </strong>
                                </p>
                                {element.triggerDataPoints.map((tdp) => {
                                  return (
                                    <p key={tdp.id}>
                                      <Badge
                                        key={tdp.dataPointType}
                                        color="primary"
                                      >
                                        {tdp.dataPointType}{' '}
                                        {tdp.value && " = '" + tdp.value + "'"}
                                      </Badge>
                                    </p>
                                  );
                                })}
                                <p>{ReactHtmlParser(element.text)}</p>
                                <p>
                                  <em>Click to edit plot point</em>
                                </p>
                              </PopoverBody>
                            </UncontrolledPopover>
                            <div
                              onClick={() =>
                                setElementEditorParams({
                                  elementId: element.id,
                                  plotPointId: pp.id,
                                })
                              }
                              id={`plot-point-${index}-${i}`}
                              className={
                                'plot-point-inner ' + contentQualityClassName
                              }
                            >
                              {ReactHtmlParser(sentence)}
                            </div>
                          </div>
                        );
                      })}
                    </TabPane>
                  ))}
                </TabContent>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardBody></CardBody>
      </Card>
      {elementEditorParams && (
        <RoutedElementEditor
          openState={!!elementEditorParams}
          customerId={customerId}
          user={currentUser}
          userLoading={userLoading}
          match={{
            params: {
              elementId: elementEditorParams.elementId,
              plotPointId: elementEditorParams.plotPointId,
              storyId,
            },
          }}
          onClose={() => setElementEditorParams(null)}
          onModified={refreshMessages}
        />
      )}
    </>
  );
};

export default EmailMessages;
