const Menu = [
  //   {
  //     heading: 'Coverage',
  //     translate: 'sidebar.heading.COVERAGE',
  //   },
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: 'fas fa-home',
    translate: 'sidebar.nav.DASHBOARD',
    requiredRolesMask: 4,
  },
  {
    heading: 'Stories',
    translate: 'sidebar.heading.STORIES',
    requiredRolesMask: 4,
  },
  {
    name: 'Stories',
    shortName: 'Stories',
    path: '/stories',
    icon: 'icon-book-open',
    translate: 'sidebar.nav.pages.STORIES',
    requiredRolesMask: 4,
  },
  {
    name: 'Messages',
    shortName: 'Messages',
    path: '/messages/pending',
    icon: 'fa fa-list-ol',
    translate: 'sidebar.nav.pages.MESSAGEQUEUE',
    // label: {
    //   value: 3,
    //   color: 'gradient-blood-orange',
    // },
    requiredRolesMask: 4,
  },
  {
    name: 'Contacts',
    shortName: 'Contacts',
    path: '/contacts',
    icon: 'icon-people',
    translate: 'sidebar.nav.pages.CONTACTS',
    requiredRolesMask: 4,
  },
  {
    name: 'Accounts',
    icon: 'far fa-building',
    translate: 'sidebar.nav.pages.ACCOUNTS',
    path: '/accounts',
    requiredRolesMask: 4,
  },
  {
    heading: 'Admin Only',
    translate: 'sidebar.heading.ADMINONLY',
    requiredRolesMask: 2,
  },
  {
    name: 'Senders',
    icon: 'fa fa-users',
    translate: 'sidebar.nav.pages.SENDERS',
    path: '/senders',
    requiredRolesMask: 2,
  },
  {
    name: 'Settings',
    translate: 'sidebar.heading.SETTINGS',
    icon: 'fa fa-cogs',
    requiredRolesMask: 2,
    submenu: [
      {
        name: 'Sending Limit',
        // icon: 'fa fa-cogs',
        translate: 'sidebar.nav.pages.SENDINGLIMIT',
        path: '/settings/overview',
        requiredRolesMask: 2,
      },
      {
        name: 'Integrations',
        // icon: 'fa fa-link',
        translate: 'sidebar.nav.pages.INTEGRATIONS',
        path: '/settings/integrations',
        requiredRolesMask: 2,
      },
      {
        name: 'Account Targeting',
        // icon: 'icon-target',
        translate: 'sidebar.nav.pages.ICP',
        path: '/settings/icp',
        requiredRolesMask: 2,
      },
      {
        name: 'Personas',
        // icon: 'fa fa-users-cog',
        translate: 'sidebar.nav.pages.PERSONAS',
        path: '/settings/personas',
        requiredRolesMask: 2,
      },
      {
        name: 'Rules of Engagement',
        // icon: 'fa fa-users-cog',
        translate: 'sidebar.nav.pages.RULESOFENGAGEMENT',
        path: '/settings/roe',
        requiredRolesMask: 2,
      },
      {
        name: 'Account Assignments',
        // icon: 'fa fa-divide',
        translate: 'sidebar.nav.pages.ACCOUNTASSIGNMENTS',
        path: '/settings/aal',
        requiredRolesMask: 2,
      },
    ],
  },
];

export default Menu;
