import React, { useState } from 'react';
import {
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  CardHeader,
  CardTitle,
  CardBody,
  Card,
} from 'reactstrap';
import { DataPointTypes } from '@koncert/shared-components';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useMutation } from '@apollo/react-hooks';
import {
  STORYQUERYSTRING,
  CREATE_TYPETRIGGERED_ELEMENT,
  UPDATE_TYPETRIGGERED_ELEMENT,
  STORIESCONTACTSTATISTICSQUERY
} from '@koncert/graphql';

import SpinnerButton from '../Extras/SpinnerButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { text } from '../Forms/FormValidatorPattern';
import { useSendersList } from '@koncert/shared-components';

const TypeTriggeredElement = ({
  element,
  plotPoint,
  isDefault,
  toggleModal,
  customerId,
  story,
  user,
  userLoading,
  onModified,
  clone = false,
}) => {
  const { handleSubmit, register, errors } = useForm({
    defaultValues: { text: element.text },
  });
  let plotPointAsDefaultId = isDefault ? plotPoint.id : null;
  let plotPointAsAdditionalId = !isDefault ? plotPoint.id : null;
  const [weight, setWeight] = useState(element.weight || 20);
  const [createTypeTriggeredElement, { loading: createLoading }] = useMutation(
    CREATE_TYPETRIGGERED_ELEMENT
  );
  const [isInvalidDualList, setIsInvalidDualList] = useState(false);
  const [updateTypeTriggeredElement, { loading: updateLoading }] = useMutation(
    UPDATE_TYPETRIGGERED_ELEMENT
  );
  const pascalToNatural = (str) => {
    str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
    str = str.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    return str;
  };

  const options = DataPointTypes.map((dp) => {
    return { value: dp, label: pascalToNatural(dp) };
  });

  const defaultSenderId = userLoading
    ? null
    : user.rolesMask < 4
    ? element.senderId
    : user.id;
  const disableSelectingOtherSenders = element.id || user?.rolesMask > 2;
  const { SendersDropdown, senderId } = useSendersList(
    customerId,
    user,
    userLoading,
    defaultSenderId,
    false,
    disableSelectingOtherSenders,
    false,
    user?.rolesMask > 2
  );
  const marks = {
    '-10': '-10',
    0: <strong className="text-info">0</strong>,
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
  };

  const [selectedRequiredData, setSelectedRequiredData] = useState(
    element.triggerDataPoints.map((dp) => {
      return dp.dataPointType;
    }) || []
  );

  const onChange = (selected) => {
    setSelectedRequiredData(selected);
  };

  const handleTriggerDataPoints = () => {
    const dps = [];
    selectedRequiredData.map((data) => {
      return dps.push({ dataPointType: data, dataSource: 'LegacyDb' });
    });
    return dps;
  };

  const onSubmit = (data) => {
    if (selectedRequiredData.length === 0) {
      setIsInvalidDualList(true);
      return false;
    }
    const { text } = data;
    const dataPoints = handleTriggerDataPoints();

    if (element.id && element.senderId === senderId) {
      updateTypeTriggeredElement({
        variables: {
          id: element.id,
          text: text,
          weight: weight,
          dataPoints: dataPoints,
          senderId: senderId,
          plotPointCategoryId: plotPoint.plotPointCategoryId,
        },
        refetchQueries: [
          {
            query: STORYQUERYSTRING,
            variables: { storyId: story.id },
            awaitRefetchQueries: true,
          },
          {
            query: STORIESCONTACTSTATISTICSQUERY,
            variables: { storyId: story.id }
          }
        ],
      }).then((result) => {
        toggleModal();
        onModified();
      });
      return;
    }

    if (!!plotPointAsDefaultId && senderId !== element.senderId) {
      // ensure it's created as an additional if this is for a different sender
      plotPointAsAdditionalId = plotPointAsDefaultId;
      plotPointAsDefaultId = null;
    }

    createTypeTriggeredElement({
      variables: {
        customerId: customerId,
        senderId: senderId,
        text: text,
        weight: weight,
        plotPointAsDefaultId: plotPointAsDefaultId,
        plotPointAsAdditionalId: plotPointAsAdditionalId,
        dataPoints: dataPoints,
        plotPointCategoryId: plotPoint.plotPointCategoryId,
      },
      refetchQueries: [
        {
          query: STORYQUERYSTRING,
          variables: { storyId: story.id },
          awaitRefetchQueries: true,
        },
        {
          query: STORIESCONTACTSTATISTICSQUERY,
          variables: { storyId: story.id }
        }
      ],
    }).then((result) => {
      toggleModal();
      onModified();
    });
    return null;
  };
  return (
    <Form name="formTypeTriggeredElement" onSubmit={handleSubmit(onSubmit)}>
      <hr />
      <Card className={'border-info'}>
        <CardHeader className="bg-info">
          <CardTitle>Type Triggered Text</CardTitle>
        </CardHeader>
        <CardBody className="">
          This text element will be selected if the Contacts has any data in the
          following selected fields...
        </CardBody>
      </Card>
      <hr />
      <FormGroup className="pb-4 bb">
        <label className="mr-2">Select Sender (optional)</label>
        <SendersDropdown />
      </FormGroup>
      <FormGroup className="pb-4">
        <label>Importance (weight)</label>
        <Slider
          dots
          marks={marks}
          step={5}
          defaultValue={weight}
          onChange={(value) => setWeight(value)}
        />
      </FormGroup>
      <hr />
      <FormGroup>
        <label>Required Data Fields</label>
        <DualListBox
          canFilter
          options={options}
          selected={selectedRequiredData}
          onChange={onChange}
        />
        {isInvalidDualList && (
          <span
            style={{
              fontSize: '80%',
              color: '#F45B53',
              marginTop: '0.25rem',
            }}
          >
            Select at least one trigger element
          </span>
        )}
      </FormGroup>
      <FormGroup>
        <label>Text</label>
        <Input
          rows={10}
          type="textarea"
          name="text"
          invalid={errors.text}
          placeholder="Text"
          innerRef={register(text)}
        />
        <ErrorMessage
          errors={errors}
          className="invalid-feedback"
          name="text"
          as="p"
        ></ErrorMessage>
      </FormGroup>
      <ModalFooter>
        {senderId !== element.senderId && senderId === user.id && (
          <>
            <span className="text-warning">
              This will create a new variant just for your messages
            </span>
          </>
        )}

        {element.senderId && senderId === null && user.rolesMask < 4 && (
          <>
            <span className="text-warning">
              This will create a new variant for all senders
            </span>
          </>
        )}

        {(!element.id ||
          (element.id &&
            senderId !== element.senderId &&
            senderId === user.id) ||
          (element.id &&
            senderId == null &&
            element.senderId &&
            user.rolesMask < 4)) && (
          <>
            <SpinnerButton
              type="submit"
              color="primary"
              loading={createLoading || updateLoading}
            >
              Create
            </SpinnerButton>
          </>
        )}

        {element.id &&
          ((user.rolesMask < 4 && element.senderId === senderId) ||
            user.id === element.senderId) && (
            <>
              <SpinnerButton
                type="submit"
                color={element.id ? 'secondary' : 'primary'}
                loading={createLoading || updateLoading}
              >
                Save
              </SpinnerButton>
            </>
          )}
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default TypeTriggeredElement;
