import React from 'react';
import { ElementEditor } from './ElementEditor';
import { useQuery } from '@apollo/react-hooks';
import {
  ELEMENT_QUERY,
  PLOTPOINT_QUERY,
  STORYQUERYSTRING,
} from '@koncert/graphql';

const RoutedElementEditor = ({
  onModified,
  customerId,
  user,
  userLoading,
  match,
  history,
  onClose,
  openState,
}) => {
  const { elementId, plotPointId, storyId } = match.params;
  const { data, loading, error } = useQuery(ELEMENT_QUERY, {
    variables: { id: elementId },
    skip: userLoading,
  });

  const {
    data: plotPointData,
    loading: plotPointLoading,
    error: plotPointError,
  } = useQuery(PLOTPOINT_QUERY, {
    variables: { id: plotPointId },
    skip: userLoading,
  });

  const {
    data: storyData,
    loading: storyLoading,
    error: storyError,
  } = useQuery(STORYQUERYSTRING, {
    variables: { storyId: storyId },
    skip: userLoading,
  });

  if (userLoading || loading || plotPointLoading || storyLoading)
    return <i className="fa fa-spin fa-spinner"></i>;

  if (error || plotPointError || storyError)
    return <i className="fa fa-exclamation-triangle text-danger"></i>;

  const element = data.v3_Customer_StoryComponents_Element || {};
  const plotPoint = plotPointData.v3_Customer_StoryComponents_PlotPoint || {};
  const story = storyData.v3_Customer_Story ?? {};

  return (
    <ElementEditor
      onModified={onModified}
      customerId={customerId || user.companyId}
      user={user}
      userLoading={userLoading}
      story={story}
      plotPoint={plotPoint}
      element={element}
      isDefault={element.plotPointAsDefaultId === plotPointId}
      openState={openState ?? true}
      history={history}
      onClose={onClose}
      hideEditButton={true}
    />
  );
};

export default RoutedElementEditor;
