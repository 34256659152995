import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';

import SidebarRun from './Sidebar.run';
import {
  KoncertCoverageLogoSmall as Logo,
  UserContext,
} from '@koncert/shared-components';

import Menu from '../../Menu.js';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <span>
      <Trans i18nKey={item.translate}>{item.heading}</Trans>
    </span>
  </li>
);

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
  <li className={isActive ? 'active' : ''}>
    <a href={`#${item.path}`} title={item.name}>
      {item.label && (
        <Badge
          tag="div"
          className="float-right bg-gradient-brand"
          color={item.label.color}
        >
          {item.label.value}
        </Badge>
      )}
      {item.icon && <em className={item.icon}></em>}
      <span>
        <Trans i18nKey={item.translate}>{item.name}</Trans>
      </span>
    </a>
  </li>
);

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => {
  return (
    <li className={isActive ? 'active' : ''}>
      <div className="nav-item" onClick={handler}>
        {item.label && (
          <Badge
            tag="div"
            className="float-right bg-gradient-brand"
            color={item.label.color}
          >
            {item.label.value}
          </Badge>
        )}
        {item.icon && <em className={item.icon}></em>}
        <span>
          <Trans i18nKey={item.translate}>{item.name}</Trans>
        </span>
      </div>
      <Collapse isOpen={isOpen}>
        <ul id={item.path} className="sidebar-nav sidebar-subnav">
          {children}
        </ul>
      </Collapse>
    </li>
  );
};

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">{item.name}</li>
);

const Sidebar = (props) => {
  const { user, loading: userLoading } = useContext(UserContext);
  const [collapse, setCollapse] = useState({});

  const closeSidebar = () => {
    props.actions.toggleSetting('asideToggled');
    window.resizeBy(window.screenX, window.screenY);
  };

  const navigator = (route) => {
    props.history.push(route);
  };

  const buildCollapseList = () => {
    const c = {};
    Menu.filter(({ heading }) => !heading).forEach(
      ({ name, path, submenu }) => {
        c[name] = routeActive(submenu ? submenu.map(({ path }) => path) : path);
      }
    );
    setCollapse(c);
  };

  useEffect(() => {
    // pass navigator to access router api
    console.log('Running SidebarRun');
    SidebarRun(navigator, closeSidebar);
    // prepare the flags to handle menu collapsed states
    buildCollapseList();

    // Listen for routes changes in order to hide the sidebar on mobile
    props.history.listen(closeSidebar);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** prepare initial state of collapse menus. Doesnt allow same route names */

  const routeActive = (paths) => {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some((p) => props.location.pathname.indexOf(p) > -1);
  };

  const toggleItemCollapse = (stateName) => {
    for (const c in collapse) {
      if (collapse[c] === true && c !== stateName)
        setCollapse({
          [c]: false,
        });
    }
    setCollapse({
      [stateName]: !collapse[stateName],
    });
  };

  const getSubRoutes = (item) => item.submenu.map(({ path }) => path);

  /** map menu config to string to determine which element to render */
  const itemType = (item) => {
    if (item.heading) return 'heading';
    if (!item.submenu) return 'menu';
    if (item.submenu) return 'submenu';
  };

  if (userLoading) return <i className="fa fa-spinner fa-spin"></i>;

  if (!user) return null;

  return (
    <aside className="aside-container">
      {/* START Sidebar (left) */}
      <div className="aside-inner">
        <div className="d-flex align-items-center">
          <a className="text-decoration-none navbar-brand text-left" href="/">
            <div className="d-flex align-items-center py-1">
              <img className="mr-2" src={Logo} alt="Logo" height="36" />
              <span className="text-sans-serif text-color-koncert-white h3 mb-0">
                Coverage
              </span>
            </div>
          </a>
        </div>
        <nav className="sidebar">
          {/* START sidebar nav */}
          <ul className="sidebar-nav">
            {/* Iterates over all sidebar items */}
            {Menu.map((item, i) => {
              if (user.rolesMask <= item.requiredRolesMask) {
                // heading
                if (itemType(item) === 'heading')
                  return <SidebarItemHeader item={item} key={i} />;
                else {
                  if (itemType(item) === 'menu')
                    return (
                      <SidebarItem
                        isActive={routeActive(item.path)}
                        item={item}
                        key={i}
                      />
                    );
                  if (itemType(item) === 'submenu') {
                    return (
                      <SidebarSubItem
                        item={item}
                        isOpen={collapse[item.name]}
                        handler={() => toggleItemCollapse(item.name)}
                        isActive={routeActive(getSubRoutes(item))}
                        key={i}
                      >
                        <SidebarSubHeader item={item} key={i} />
                        {item.submenu.map((subitem, i) => (
                          <SidebarItem
                            key={i}
                            item={subitem}
                            isActive={routeActive(subitem.path)}
                          />
                        ))}
                      </SidebarSubItem>
                    );
                  }
                }
                return null; // unrecognized item
              } else return null;
            })}
          </ul>
          {/* END sidebar nav */}
        </nav>
      </div>
      {/* END Sidebar (left) */}
    </aside>
  );
};

Sidebar.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
