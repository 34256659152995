import gql from 'graphql-tag';

export const PAUSE_SEQUENCE = gql`
  mutation PauseSequence($customerId: ID!) {
    pauseSequence(customerId: $customerId)
  }
`;

export const RESUME_SEQUENCE = gql`
  mutation resumeSequence($customerId: ID!) {
    resumeSequence(customerId: $customerId)
  }
`;
