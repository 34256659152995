import gql from 'graphql-tag';

export const JOB_QUERY = gql`
  query job($id: ID!) {
    job(where: { id: $id }) {
      id
      jid
      statusText
      arguments
      status
      className
      startTime
      endTime
      parentJobId
      parentJob {
        id
        className
      }
      childJobs {
        id
        className
      }
      customer {
        id
        name
      }
    }
  }
`;
