import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  NavItem,
} from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import { Link, withRouter } from 'react-router-dom';
import AdminHeader from './AdminHeader';

import {
  KoncertCoverageLogoSmall as Logo,
  KoncertLogoSmall as KoncertLogo,
  Avatar,
} from '@koncert/shared-components';
import { SIGNOUT_MUTATION } from '../mutations/UserMutations';
import { useMutation } from '@apollo/react-hooks';
import { useTracking } from '../SegmentTracker';
import { UserContext } from '@koncert/shared-components';
import useNotifications from '../hooks/useNotifications';

const Header = ({ actions, history, ...props }) => {
  const { user, loading: userLoading, error, logout } = useContext(UserContext);
  const tracker = useTracking();
  const [signoutProvider] = useMutation(SIGNOUT_MUTATION);
  const { Notifications } = useNotifications(user, userLoading, history);

  const handleLogout = () => {
    tracker.track('User Logout Clicked');
    signoutProvider({}).then((result) => {
      if (result.data.logout) {
        actions.signOut();
        actions.removeCustomer();
        logout();
        tracker.track('User Logged Out');
        history.push('/login');
      }
    });
  };
  const connectActivatedIntegration = (e) => {
    const provider = e;
    if (process.env.NODE_ENV !== 'production') {
      window.location =
        'https://dev.koncert.com/users/auth/' + provider + '?origin=' + user.id;
    } else {
      window.location =
        process.env.REACT_APP_OAUTH_URI + provider + '?origin=' + user.id;
    }
  };

  const handleXdrDownload = () => {
    history.push('/onboarding');
  };

  if (userLoading) return null;
  if (error || !user) {
    actions.signOut();
    actions.removeCustomer();
    logout();
    history.push('/login');
    return null;
  }

  // const toggleUserblock = (e) => {
  //   e.preventDefault();
  //   actions.toggleSetting('showUserBlock');
  // };

  // const toggleOffsidebar = (e) => {
  //   e.preventDefault();
  //   actions.toggleSetting('offsidebarOpen');
  // };

  const toggleCollapsed = (e) => {
    e.preventDefault();
    actions.toggleSetting('isCollapsed');
    actions.toggleSetting('isCollapsedText');
    resize();
  };

  const toggleAside = (e) => {
    e.preventDefault();
    actions.toggleSetting('asideToggled');
  };

  const resize = () => {
    // all IE friendly dispatchEvent
    const evt = document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  };
  const _avatar = user ? user.imageUrl || Avatar : Avatar;
  return (
    <header className="topnavbar-wrapper">
      {/* START Top Navbar */}
      <nav className="navbar topnavbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center pl-4">
            <a
              className="text-decoration-none navbar-brand text-left"
              href="#/dashboard"
            >
              <div className="d-flex align-items-center py-1">
                <img className="mr-2" src={Logo} alt="Logo" width="38" />
                <span className="text-sans-serif text-color-koncert-white h3 mb-0">
                  Coverage
                </span>
              </div>
            </a>
          </div>
        </div>
        {/* START Left navbar */}
        <ul className="navbar-nav mr-auto flex-row">
          <li className="nav-item">
            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
            <a
              href="#/"
              className="nav-link d-none d-md-block d-lg-block d-xl-block"
              onClick={toggleCollapsed}
            >
              <em className="fas fa-bars"></em>
            </a>
            {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
            <a
              href="#/"
              className="nav-link sidebar-toggle d-md-none"
              onClick={toggleAside}
            >
              <em className="fas fa-bars"></em>
            </a>
          </li>
          {/* START User avatar toggle */}
          <UncontrolledDropdown nav inNavbar className="dropdown-list">
            <DropdownToggle nav className="dropdown-toggle-nocaret">
              <em className="icon-user"></em>
            </DropdownToggle>
            <DropdownMenu className="animated flipInX">
              <DropdownItem>
                {/* START list group */}
                <ListGroup>
                  <ListGroupItem
                    action
                    tag="a"
                    href="/user/settings"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div>
                      <div className="item user-block">
                        {/* User picture */}
                        <div className="user-block-picture">
                          <div className="user-block-status">
                            <img
                              className="img-thumbnail rounded-circle"
                              src={_avatar}
                              alt="Avatar"
                              width="60"
                              height="60"
                            />
                            <div className="circle bg-success circle-lg"></div>
                          </div>
                        </div>
                        {/* Name and Job */}
                        <div className="user-block-info">
                          <span className="user-block-name">
                            Hello, {user.firstName}
                          </span>
                          <span className="user-block-role">
                            {user.title}
                            <br />
                            {user.roles && user.roles.join(',')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>
                  {user.onlineTime === 0 && (
                    <ListGroupItem onClick={handleXdrDownload}>
                      <span className="d-flex align-items-center">
                        <span className="text-sm">Onboarding</span>
                      </span>
                    </ListGroupItem>
                  )}
                  {user.sfdcIdentity == null && (
                    <ListGroupItem
                      onClick={() => connectActivatedIntegration('salesforce')}
                    >
                      <span className="d-flex align-items-center">
                        <span className="text-sm">Connect to Salesforce</span>
                      </span>
                    </ListGroupItem>
                  )}
                  {user.connectleaderIdentity == null && (
                    <ListGroupItem
                      onClick={() =>
                        connectActivatedIntegration('connectleader')
                      }
                    >
                      <span className="d-flex align-items-center">
                        <span className="text-sm">
                          Connect to ConnectLeader
                        </span>
                      </span>
                    </ListGroupItem>
                  )}
                  <ListGroupItem onClick={handleLogout}>
                    <span className="d-flex align-items-center">
                      <span className="text-sm" defaultValue>
                        Logout
                      </span>
                    </span>
                  </ListGroupItem>
                </ListGroup>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <Notifications />
          {(user.company.senderDailySendingLimit === 0 ||
            user.dailySendingLimit === 0) && (
            <NavItem className="d-none d-md-block">
              {user.rolesMask < 4 && (
                <Link className="nav-link" to="/settings/overview">
                  <i className="fa fa-exclamation-triangle fa-gradient-blood-orange mr-2"></i>
                  Sending Disabled
                </Link>
              )}
              {user.rolesMask === 4 && (
                <div className="nav-link">
                  <i className="fa fa-exclamation-triangle fa-gradient-blood-orange mr-2"></i>
                  Sending Disabled
                </div>
              )}
            </NavItem>
          )}
          {/* END User avatar toggle */}
          {/* START lock screen */}
          {/* <li className="nav-item d-none d-md-block">
              <Link to="lock" title="Lock screen" className="nav-link">
                <em className="icon-lock"></em>
              </Link>
            </li> */}
          {/* END lock screen */}
        </ul>
        {user && user.rolesMask === 1 && <AdminHeader />}
        {/* END Left navbar */}
        {/* START Right Navbar */}
        <ul className="navbar-nav flex-row">
          {/* <li className="nav-item d-none d-md-block">
            <a href="#/" className="nav-link" data-search-open="">
              <img
                class="mr-2 float-left"
                src={KoncertLogo}
                alt="KoncertLogo"
                height="20"
              />
              <em className="fa fa-th"></em>
            </a>
          </li> */}
          {/* Search icon */}
          {/* <li className="nav-item">
            <a href="#/" className="nav-link" data-search-open="">
              <em className="icon-magnifier"></em>
            </a>
          </li> */}
          {/* Fullscreen (only desktops) */}
          {/* <li className="nav-item d-none d-md-block">
            <ToggleFullscreen className="nav-link" />
          </li> */}
          <UncontrolledDropdown nav inNavbar className="dropdown-list">
            <DropdownToggle nav className="dropdown-toggle-nocaret">
              <img
                className="mr-2 float-left"
                src={KoncertLogo}
                alt="KoncertLogo"
                height="20"
              />
              <em className="fa fa-th"></em>
            </DropdownToggle>
            <DropdownMenu right className="animated bounceIn">
              <DropdownItem>
                <ListGroup>
                  <ListGroupItem
                    action
                    tag="a"
                    href="https://cadence.koncert.com"
                  >
                    Cadence
                  </ListGroupItem>
                  <ListGroupItem
                    action
                    tag="a"
                    href="https://dialers.koncert.com"
                  >
                    Dialers
                  </ListGroupItem>
                </ListGroup>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* START Offsidebar button */}

          {/* END Offsidebar menu */}
        </ul>
        {/* END Right Navbar */}

        {/* START Search form */}
        {/* <form className="navbar-form" role="search" action="search.html">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Type and hit enter ..."
            />
            <div
              className="fa fa-times navbar-form-close"
              data-search-dismiss=""
            ></div>
          </div>
          <button className="d-none" type="submit">
            Submit
          </button>
        </form> */}
        {/* END Search form */}
      </nav>
      {/* END Top Navbar */}
    </header>
  );
};

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
