import React, { useEffect } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  USER_STORY_CONTACTS_QUERY,
  STORY_CONTACT_STATUS,
  MARK_STORY_CONTACT_AS_SEEN,
} from '@koncert/graphql';

import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  useTable,
  useSortBy,
  useExpanded,
  Button,
} from '@koncert/shared-components';
import { BadgeStatus } from '@koncert/shared-components';
import moment from 'moment';

const ApproveOrPause = ({
  storyContactId,
  status,
  approved,
  filter,
  convertedSortBy,
  pageSize,
  ...rest
}) => {
  const [updateContactStatus] = useMutation(STORY_CONTACT_STATUS);
  const pauseContact = (storyContactId) => {
    updateContactStatus({
      variables: { id: storyContactId, status: 'Paused' },
      refetchQueries: [
        {
          query: USER_STORY_CONTACTS_QUERY,
          variables: {
            filter: filter,
            order: convertedSortBy,
            limit: pageSize,
          },
        },
      ],
    });
  };

  const activateContact = (storyContactId) => {
    updateContactStatus({
      variables: { id: storyContactId, status: 'New', approved: true },
      refetchQueries: [
        {
          query: USER_STORY_CONTACTS_QUERY,
          variables: {
            filter: filter,
            order: convertedSortBy,
            limit: pageSize,
          },
        },
      ],
    });
  };

  const btnClass = status !== 'Paused' ? 'text-muted' : 'text-danger';
  const btnApprovedClass = approved ? 'text-success' : 'text-muted';
  return (
    <div {...rest}>
      <Button
        disabled={approved}
        onClick={(e) => {
          e.preventDefault();
          activateContact(storyContactId);
          return false;
        }}
        className={`btn btn-secondary ${btnApprovedClass}`}
      >
        <i className="fa fa-thumbs-up"></i>
      </Button>
      <Button
        disabled={status === 'Paused'}
        onClick={(e) => {
          e.preventDefault();
          pauseContact(storyContactId);
          return false;
        }}
        className={`btn btn-secondary ${btnClass}`}
      >
        <i className="fa fa-thumbs-down"></i>
      </Button>
    </div>
  );
};

export const useMessageQueue = (
  customerId,
  currentUser,
  userLoading,
  selectedSenderId,
  sendersLoading,
  pageSize,
  statuses,
  ExpandedRow,
  xdr = false
) => {
  let initialOrder = {
    priority: 'ASC',
    personalizationScore: 'DESC',
  };

  const filter = {
    customerId: customerId || currentUser.companyId,
  };

  const [markStoryContactAsSeen] = useMutation(MARK_STORY_CONTACT_AS_SEEN);

  if (statuses && Object.keys(statuses).length > 0) {
    filter['status_in'] = Object.keys(statuses)
      .map((key) => {
        if (statuses[key]) return key;
        else return null;
      })
      .filter((key) => {
        return key !== null;
      });
    if (!statuses.New) {
      initialOrder = {
        updatedAt: 'ASC',
      };
    }
  }

  if (selectedSenderId) {
    filter['senderId'] = selectedSenderId;
  }

  if (xdr) {
    filter['senderId'] = currentUser.id;
  }

  const convertSortBy = ({ sortBy }) => {
    const newOrder = {};
    sortBy &&
      sortBy.map((sort) => {
        let mappedOrder = '';
        switch (sort.id) {
          case 'priorityBadge':
            mappedOrder = 'priority';
            break;
          case 'personalization':
            mappedOrder = 'personalizationScore';
            break;
          default:
            mappedOrder = sort.id;
            break;
        }
        return (newOrder[mappedOrder] = sort.desc ? 'DESC' : 'ASC');
      });
    return Object.keys(newOrder).length ? newOrder : initialOrder;
  };
  const convertedSortBy = convertSortBy(initialOrder);

  const columns = React.useMemo(
    () => [
      {
        accessor: 'lastViewedAt',
        disableSortBy: true,
        width: '1%',
        Cell: ({ row }) => {
          return (
            <>
              {!row.original.lastViewedAt && (
                <i className="circle circle-md bg-gradient-brand"></i>
              )}
              {row.original.lastViewedAt && (
                <i className="circle circle-md bg-koncert-white"></i>
              )}
            </>
          );
        },
      },
      {
        // Make an expander cell
        Header: () => null,
        Cell: ({ row, rows, toggleRowExpanded }) => (
          <span
            {...row.getToggleRowExpandedProps({
              onClick: () => {
                if (
                  row.original.lastViewedAt === null &&
                  row.original.senderId === currentUser?.id
                ) {
                  markStoryContactAsSeen({
                    variables: {
                      id: row.original.id,
                      lastViewedAt: new Date(),
                    },
                  });
                }
                console.log('expand row');

                row.toggleRowExpanded();
              },
            })}
          >
            {row.isExpanded ? (
              <i
                className={'fa fa-2x fa-angle-down fa-gradient-gothic-sky'}
              ></i>
            ) : (
              <i
                className={'fa fa-2x fa-angle-right fa-gradient-gothic-sky'}
              ></i>
            )}
          </span>
        ),
        id: 'expander', // It needs an ID
        disableSortBy: true,
        width: '1%',
        // Cell: ({ row }) => (
        //   <span>
        //     {row.isExpanded ? (
        //       <i className={'fa fa-angle-down'}></i>
        //     ) : (
        //       <i className={'fa fa-angle-right'}></i>
        //     )}
        //   </span>
        // ),
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        width: '75',
        disableSortBy: false,
        hideOnSmall: true,
        Cell: ({ cell: { value } }) => (
          <div className="badge bg-gradient-brand text-color-koncert-white">
            {value}
          </div>
        ),
      },
      {
        Header: 'Touches',
        accessor: 'emailContent',
        width: '100',
        disableSortBy: false,
        hideOnSmall: true,
        Cell: ({ cell: { value } }) => {
          const touches = Object.keys(value).map((key) => {
            if (key !== '__typename')
              return <i key={key} className="circle circle-md"></i>;
            else return null;
          });
          return touches;
        },
      },
      {
        Header: 'Story',
        accessor: 'story',
        disableSortBy: true,
        hideOnSmall: true,
        Cell: ({ cell: { value } }) => {
          return <Link to={`/stories/${value.id}`}>{value.name}</Link>;
        },
      },
      {
        Header: 'Account',
        accessor: 'account.nameValue',
        disableSortBy: true,
        hideOnSmall: true,
      },
      {
        Header: 'Contact',
        accessor: 'contact',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
          <>
            <Link to={'/contacts/' + value.id}>
              {value.givenNameValue} {value.familyNameValue}
              {!xdr && <br />}
              {!xdr && <small>{value.position.email}</small>}
            </Link>
            <br />
            <small>{value.position.title}</small>
          </>
        ),
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        disableSortBy: false,
        hideOnSmall: true,
        Cell: ({ cell: { value } }) => <span>{moment(value).fromNow()}</span>,
      },
      {
        Header: 'Personalization',
        accessor: 'personalizationScore',
        disableSortBy: false,
        hideOnSmall: true,
        Cell: ({ cell: { value } }) => {
          const progressValue = value / 10;
          const progressbarClass =
            progressValue > 50
              ? 'bg-success'
              : progressValue > 20
              ? 'bg-warning'
              : 'bg-danger';
          const progressbarTextClass =
            progressValue > 50 ? 'text-gray-lighter' : 'text-dark';
          return (
            <div className="progress progress-md position-relative">
              <div
                className={`progress-bar progress-bar-striped ${progressbarClass}`}
                role="progressbar"
                style={{ width: progressValue + '%' }}
              >
                <div
                  className={`justify-content-center d-flex position-absolute w-100 ${progressbarTextClass}`}
                >
                  {value}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Sender',
        accessor: 'sender.fullName',
        disableSortBy: false,
        hideOnSmall: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: false,
        Cell: ({ cell: { value } }) => {
          const statusClass = BadgeStatus[value];
          return (
            <small>
              <span className={`inline wd-xs badge ${statusClass}`}>
                {value}
              </span>
            </small>
          );
        },
      },
      {
        Header: 'Approve/Pause',
        accessor: 'id',
        disableSortBy: true,
        disableExpanded: true,
        Cell: ({ row }) => {
          return (
            <ApproveOrPause
              storyContactId={row.original.id}
              status={row.original.status}
              approved={row.original.approved}
              filter={filter}
              convertedSortBy={convertedSortBy}
              pageSize={pageSize}
            />
          );
        },
      },
    ], // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSenderId, filter, currentUser]
  );

  const { loading, data, error, refetch } = useQuery(
    USER_STORY_CONTACTS_QUERY,
    {
      variables: {
        filter: {
          ...filter,
        },
        order: convertedSortBy,
        limit: pageSize,
      },
      skip: userLoading || sendersLoading,
    }
  );

  useEffect(() => {
    // ensure that when the list of statuses change we refetch because the status of one or more
    // story contacts may have changed whilst viewing the previous list of statuses
    refetch();
  }, [filter?.status_in]);

  const messageQueueTableProps = {
    userLoading,
    loading,
    sendersLoading,
    error,
    columns,
    data,
    customerId,
    currentUser,
  };

  return {
    MessageQueueTable,
    messageQueueTableProps,
    loading,
    data,
    error,
    markStoryContactAsSeen,
    ApproveOrPause,
    filter,
  };
};

const MessageQueueTable = ({
  userLoading,
  loading,
  sendersLoading,
  error,
  columns,
  data,
  ExpandedRow,
  customerId,
  currentUser,
}) => {
  useEffect(() => {
    console.log('in MessageQueueTable useEffect');
  }, []);

  const getSortingClassName = (sorted, isSortedDesc, disableSortBy) => {
    return !disableSortBy
      ? sorted
        ? isSortedDesc
          ? 'sorting_desc'
          : 'sorting_asc'
        : 'sorting'
      : '';
  };

  const initialState = {
    pageIndex: 0,
    pageSize: 10,
    sortBy: [
      { id: 'priority', desc: false },
      { id: 'personalizationScore', desc: true },
    ],
  };

  const getDisplayClassName = (hideOnSmall) => {
    return hideOnSmall ? 'd-none d-lg-table-cell' : '';
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
  } = useTable(
    {
      columns,
      data: data?.v3_Customer_StoryContacts || [],
      initialState: initialState,
      autoResetExpanded: false,
    },
    useSortBy,
    useExpanded
  );

  if (userLoading || loading || sendersLoading)
    return (
      <Row>
        <Col>
          <i className="fa fa-spinner fa-spin fa-2x"></i>
        </Col>
      </Row>
    );

  if (error)
    return (
      <Row>
        <Col>
          <i className="fa fa-exclamation-circle fa-2x"></i>
        </Col>
      </Row>
    );

  return (
    <div id={`contacts_wrapper`} className="">
      <Table striped hover size="sm" {...getTableProps()} className="mb-mails">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <td
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`${getSortingClassName(
                    column.isSorted,
                    column.isSortedDesc,
                    column.disableSortBy
                  )} ${getDisplayClassName(column.hideOnSmall)}`}
                  width={column.width}
                >
                  {column.render('Header')}
                  <span className="ml-2">
                    {!column.disableSortBy ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fa fa-sort-down"></i>
                        ) : (
                          <i className="fa fa-sort text-muted"></i>
                        )
                      ) : (
                        <i className="fa fa-sort text-muted"></i>
                      )
                    ) : (
                      <span></span>
                    )}
                  </span>
                </td>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            const rowProps = Object.assign({}, row.getRowProps());
            return (
              <React.Fragment key={index}>
                <tr
                  className={'' + (row.id % 2 ? 'odd' : 'even')}
                  {...rowProps}
                >
                  {row.cells.map((cell) => {
                    if (!cell.column.disableExpanded) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${getDisplayClassName(
                            cell.column.hideOnSmall
                          )}`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          {...cell.getCellProps()}
                          width={cell.width}
                          className={`${getDisplayClassName(
                            cell.column.hideOnSmall
                          )}`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    }
                  })}
                </tr>
                {/*
                          If the row is in an expanded state, render a row with a
                          column that fills the entire length of the table.
                        */}
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={allColumns.length}>
                      {/*
                                Inside it, call our renderRowSubComponent function. In reality,
                                you could pass whatever you want as props to
                                a component like this, including the entire
                                table instance. But for this example, we'll just
                                pass the row
                              */}
                      <ExpandedRow
                        customerId={customerId}
                        currentUser={currentUser}
                        userLoading={userLoading}
                        row={row}
                      />
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
          {rows.length === 0 && (
            <tr>
              <td
                colSpan={allColumns.length}
                className="justify-content-center text-center"
              >
                <h2>No Contacts Found</h2>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default useMessageQueue;
