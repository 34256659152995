import React from 'react';
import { Label } from 'reactstrap';
import moment from 'moment';

export const JobDetails = (props) => {
  if (props.loading) return <i className="fa fa-spinner fa-spin"></i>;

  const job = props.job;
  let duration = '-';
  if (job.endTime) {
    let difference = moment(job.endTime).diff(moment(job.startTime));
    duration = moment.duration(difference).humanize();
  }
  let statusColor = null;
  switch (job.status) {
    case 'success':
      statusColor = 'success';
      break;
    case 'error':
    case 'terminated':
      statusColor = 'danger';
      break;
    default:
      statusColor = 'info';
  }

  return (
    <dl className="dl-horizontal">
      <dt>Name:</dt>
      <dd>{job.className}</dd>
      <dt>Arguments:</dt>
      <dd>{job.arguments}</dd>
      <dt>Status:</dt>
      <dd>
        <Label bsStyle={statusColor}>{job.status}</Label>
      </dd>
      {!!job.statusText && (
        <React.Fragment>
          <dt>Status Text:</dt>
          <dd>{job.statusText}</dd>
        </React.Fragment>
      )}
      <dt>Start:</dt>
      <dd>{job.startTime}</dd>
      <dt>End:</dt>
      <dd>{job.endTime || '-'}</dd>
      <dt>Duration:</dt>
      <dd>{duration}</dd>
      {!!job.parentJob && (
        <React.Fragment>
          <dt>Parent Job:</dt>
          <dd>
            <a href={`/v2#/admin/jobs/${job.parentJob.id}`}>
              {job.parentJob.className}
            </a>
          </dd>
        </React.Fragment>
      )}
      {!!job.customer && (
        <React.Fragment>
          <dt>Customer:</dt>
          <dd>
            <a href={`/v2#/customers/${job.customer.id}`}>
              {job.customer.name}
            </a>
          </dd>
        </React.Fragment>
      )}
      <dt>Id:</dt>
      <dd>{job.id}</dd>
      <dt>Jid:</dt>
      <dd>{job.jid}</dd>
    </dl>
  );
};
