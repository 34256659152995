import gql from 'graphql-tag';

export const STORIESCONTACTSTATISTICSQUERY = gql`
query($storyId: ID!) {
  v3_Customer_Story(id: $storyId) {
      id
      eligibleContactAnalysis
    }
  }
`;
