import React, { useState } from 'react';
import {
  Button,
  ModalFooter,
  Form,
  FormGroup,
  CardHeader,
  CardTitle,
  CardBody,
  Card,
  Input,
} from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';
import {
  STORYQUERYSTRING,
  CREATE_STATIC_ELEMENT,
  UPDATE_STATIC_ELEMENT,
  STORIESCONTACTSTATISTICSQUERY
} from '@koncert/graphql';

import SpinnerButton from '../Extras/SpinnerButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { text } from '../Forms/FormValidatorPattern';
import { useSendersList } from '@koncert/shared-components';

const StaticElement = ({
  element,
  plotPoint,
  isDefault,
  toggleModal,
  customerId,
  story,
  user,
  userLoading,
  onModified,
  clone = false,
}) => {
  const { handleSubmit, register, errors } = useForm({
    defaultValues: { text: element.text },
  });
  const plotPointAsDefaultId = isDefault ? plotPoint.id : null;
  const plotPointAsAdditionalId = !isDefault ? plotPoint.id : null;
  const [weight] = useState(element.weight || 0);

  const [createStaticElement, { loading: createLoading }] = useMutation(
    CREATE_STATIC_ELEMENT
  );
  const [updateStaticElement, { loading: updateLoading }] = useMutation(
    UPDATE_STATIC_ELEMENT
  );

  const defaultSenderId = userLoading
    ? null
    : user.rolesMask < 4
    ? element.senderId
    : user.id;
  const disableSelectingOtherSenders = element.id || user?.rolesMask > 2;
  const { SendersDropdown, senderId } = useSendersList(
    customerId,
    user,
    userLoading,
    defaultSenderId,
    false,
    disableSelectingOtherSenders,
    false,
    user?.rolesMask > 2
  );

  const onSubmit = (data) => {
    const { text } = data;

    if (element.id && element.senderId === senderId) {
      updateStaticElement({
        variables: {
          id: element.id,
          text: text,
          weight: weight,
          senderId: senderId,
          plotPointCategoryId: plotPoint.plotPointCategoryId,
        },
        refetchQueries: [
          {
            query: STORYQUERYSTRING,
            variables: { storyId: story.id },
            awaitRefetchQueries: true,
          },
          {
            query: STORIESCONTACTSTATISTICSQUERY,
            variables: { storyId: story.id }
          }
        ],
      }).then((result) => {
        toggleModal();
        onModified();
      });
      return;
    }

    if (!!plotPointAsDefaultId && senderId !== element.senderId) {
      // ensure it's created as an additional if this is for a different sender
      plotPointAsAdditionalId = plotPointAsDefaultId;
      plotPointAsDefaultId = null;
    }

    createStaticElement({
      variables: {
        customerId: customerId,
        text: text,
        weight: weight,
        senderId: senderId,
        plotPointAsDefaultId: plotPointAsDefaultId,
        plotPointAsAdditionalId: plotPointAsAdditionalId,
        plotPointCategoryId: plotPoint.plotPointCategoryId,
      },
      refetchQueries: [
        {
          query: STORYQUERYSTRING,
          variables: { storyId: story.id },
          awaitRefetchQueries: true,
        },
        {
          query: STORIESCONTACTSTATISTICSQUERY,
          variables: { storyId: story.id }
        }
      ],
    }).then((result) => {
      toggleModal();
      onModified();
    });
  };
  return (
    <Form name="formStaticElement" onSubmit={handleSubmit(onSubmit)}>
      <hr />
      <Card className={'border-info'}>
        <CardHeader className="bg-info">
          <CardTitle>Static Text Trigger</CardTitle>
        </CardHeader>
        <CardBody className="">
          Static text elements are not allowed to contain any variables
        </CardBody>
      </Card>
      <hr />
      <FormGroup className="pb-4 bb">
        <label className="mr-2">Select Sender (optional)</label>
        <SendersDropdown />
      </FormGroup>
      <FormGroup>
        <label>Text</label>
        <Input
          className="form-control"
          type="textarea"
          name="text"
          invalid={errors.text}
          rows={10}
          placeholder="Text"
          innerRef={register(text)}
        />
        <ErrorMessage
          errors={errors}
          className="invalid-feedback"
          name="text"
          as="p"
        ></ErrorMessage>
      </FormGroup>

      <ModalFooter>
        {senderId !== element.senderId && senderId === user.id && (
          <>
            <span className="text-warning">
              This will create a new variant just for your messages
            </span>
          </>
        )}

        {element.senderId && senderId === null && user.rolesMask < 4 && (
          <>
            <span className="text-warning">
              This will create a new variant for all senders
            </span>
          </>
        )}

        {(!element.id ||
          (element.id &&
            senderId !== element.senderId &&
            senderId === user.id) ||
          (element.id &&
            senderId == null &&
            element.senderId &&
            user.rolesMask < 4)) && (
          <>
            <SpinnerButton
              type="submit"
              color="primary"
              loading={createLoading || updateLoading}
            >
              Create
            </SpinnerButton>
          </>
        )}

        {element.id &&
          ((user.rolesMask < 4 && element.senderId === senderId) ||
            user.id === element.senderId) && (
            <>
              <SpinnerButton
                type="submit"
                color={element.id ? 'secondary' : 'primary'}
                loading={createLoading || updateLoading}
              >
                Save
              </SpinnerButton>
            </>
          )}
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default StaticElement;
