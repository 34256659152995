import gql from "graphql-tag";

export const CONTACTS_QUERY = gql`
  query v3_customer_contacts($filter: V3_Customer_ContactFilter, $limit: Int, $skip: Int) {
    v3_Customer_Contacts(limit: $limit, skip: $skip, where: $filter) {
      id
      avatarsValue
      sender {
        fullName
      }
      customerAccount {
        id
        nameValue
        salesforceIdsValue
      }
      salesforceIdsValue
      lastApproachedAt
      lastContactedAt
      familyNameValue
      givenNameValue
      linkedinUrlsValue
      position {
        id
        email
        title
      }
      status
    },
    _v3_Customer_ContactsMeta(where: $filter) {
      count
    }
  }
`;