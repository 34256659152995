import gql from 'graphql-tag';

export const EVENTS_LOGS_COUNT_QUERY = gql`
  query eventLogsCount($filterQuery: EventLogFilter!) {
    _eventLogsMeta(where: $filterQuery) {
      count
    }
  }
`;
export const EVENT_LOGS_QUERY = gql`
  query EVENT_LOGS_QUERY(
    $filterQuery: EventLogFilter!
    $skip: Int = 0
    $limit: Int = 100
  ) {
    eventLogs(
      order: { createdAt: DESC }
      where: $filterQuery
      skip: $skip
      limit: $limit
    ) {
      id
      createdAt
      level
      event
      outcome
      path
      lineno
      label
      message
      user {
        id
        email
      }
      customer {
        id
        name
      }
      data
      backtrace
    }
  }
`;
