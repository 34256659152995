import gql from 'graphql-tag';

export const STORYQUERYSTRING = gql`
  query($storyId: ID!) {
    v3_Customer_Story(id: $storyId) {
      id
      name
      priority
      storyType
      customerId
      rulesOfEngagementId
      accountSelectorId
      contactSelectorId
      requiredDataPoints
      optionalDataPoints
      sendingWindowDayStart
      sendingWindowDayEnd
      sendingWindowHourStart
      sendingWindowHourEnd
      rulesOfEngagement {
        id
        name
        days
      }
      accountSelector {
        id
        name
        requiredDataPoints {
          id
          value
          dataPointType
        }
      }
      contactSelector {
        id
        name
        requiredDataPoints {
          id
          value
          dataPointType
        }
      }
      messages {
        id
        name
        position
        plotPoints {
          id
          name
          position
          plotPointCategoryId
          defaultElement {
            id
            weight
            text
            triggerType
            senderId
            triggerDataPoints {
              id
              value
              dataPointType
            }
          }
          additionalElements {
            id
            weight
            text
            triggerType
            senderId
            triggerDataPoints {
              id
              value
              dataPointType
            }
          }
        }
        _plotPointsMeta {
          count
        }
      }
    }
  }
`;
