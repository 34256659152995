import React, { useState, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import ContentWrapper from '../Layout/ContentWrapper';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  ButtonToolbar,
} from 'reactstrap';
import {
  UserContext,
  ContactsDataTable,
  useSendersList,
} from '@koncert/shared-components';
import { connect } from 'react-redux';

const SubMenu = ({ SendersDropdown }) => {
  const [selectedSection] = useState('Personalization');

  return (
    <div className="content-heading">
      <div>
        Stories
        <small>{selectedSection}</small>
      </div>

      <div className="ml-auto">
        <ButtonToolbar>
          <SendersDropdown />
        </ButtonToolbar>
      </div>
    </div>
  );
};

const Personalization = ({ customerId }) => {
  const { user, loading: userLoading } = useContext(UserContext);

  const { SendersDropdown, senderId } = useSendersList(
    customerId,
    user,
    userLoading
  );

  return (
    <ContentWrapper>
      <SubMenu SendersDropdown={SendersDropdown} />
      <Row>
        <Col xl={12}>
          <Card className="card-default">
            <CardHeader>
              <h4>Personalization</h4>
            </CardHeader>
            <CardBody>
              <ContactsDataTable
                customerId={customerId}
                currentUser={user}
                userLoading={userLoading}
                senderId={senderId}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({ customerId: state.customer.id });

export default withTranslation('translations')(
  connect(mapStateToProps)(Personalization)
);
