import gql from 'graphql-tag';

export const CONTACTCOVERAGEAGGREGATION = gql`
  query contactCoverageAggregation(
    $customerId: ID!
    $format: String = "day"
    $startDate: DateTime
    $endDate: DateTime
    $senderId: ID
  ) {
    contactCoverageAggregation(
      customerId: $customerId
      format: $format
      startDate: $startDate
      endDate: $endDate
      senderId: $senderId
    ) {
      id
      startDate
      endDate
      data
      totalCount
      totalContactCount
      totalDnc
    }
  }
`;

export default CONTACTCOVERAGEAGGREGATION;
