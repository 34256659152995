import React from 'react';
import Error500 from '../Pages/Error500';
import LicenseError from '../Pages/LicenseError';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, hasLicenseError: false };
  }

  static getDerivedStateFromError(error) {
    if (error.message === 'Invalid license') {
      return { hasError: true, hasLicenseError: true };
    } else {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasLicenseError) {
      // license error.
      return <LicenseError {...this.props} />;
    }
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Error500 {...this.props} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
