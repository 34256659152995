import React, { useState, useMemo, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Sparklines, SparklinesLine } from 'react-sparklines';
import { LinearGradientFill } from '@koncert/shared-components';
import { STORYJOURNALAGGREGATION } from '@koncert/graphql';
import { useQuery } from '@apollo/react-hooks';
import { UserContext } from '@koncert/shared-components';

const StoryJournalGraph = ({
  customerId,
  title,
  color,
  bgColorClass,
  startColor,
  endColor,
  event,
  format,
  startDate,
  endDate,
  storyId,
  groupByStoryId,
  senderId,
  groupBySender,
  groupByAccount,
  accountId,
  darkText,
}) => {
  const { user, loading: userLoading } = useContext(UserContext);

  const [fixedData, setFixedData] = useState([2, 4, 6, 12, 23, 30]);
  const [totalData, setTotalData] = useState(0);

  const { loading, data } = useQuery(STORYJOURNALAGGREGATION, {
    variables: {
      customerId: customerId || user.companyId,
      format: format,
      event: event,
      startDate: startDate,
      endDate: endDate,
      storyId: storyId,
      groupByStoryId: groupByStoryId,
      senderId: groupBySender ? senderId || user.id : null,
      groupBySender: groupBySender,
      groupByAccount: groupByAccount,
      accountId: accountId,
    },
    skip: userLoading,
  });

  useMemo(() => {
    if (data && data.storyJournalAggregation) {
      const dataArray = data.storyJournalAggregation.data.map((x) => x.count);
      setFixedData(dataArray.concat(Array(5).fill(1)).slice(0, 5));
      setTotalData(
        groupByAccount
          ? data.storyJournalAggregation.totalAccountCount
          : data.storyJournalAggregation.totalCount
      );
    }
  }, [data, groupByAccount]);

  return (
    <div className={'card ' + bgColorClass + ' pt-2 b'}>
      <div className={darkText === true ? 'px-2 text-dark' : 'px-2 '}>
        <div className="h2 mt0">
          {loading && <i className="fa fa-spinner fa-spin"></i>}
          {!loading && <span>{totalData}</span>}
        </div>
        <div className="text-uppercase">{title}</div>
      </div>

      {
        loading && '...'
        //   options={{
        //     type: 'line',
        //     width: '100%',
        //     height: '75px',
        //     lineColor: color,
        //     chartRangeMin: '0',
        //     fillColor: color,
        //     spotColor: color,
        //     minSpotColor: color,
        //     maxSpotColor: color,
        //     highlightSpotColor: color,
        //     highlightLineColor: color,
        //     resize: true,
        //   }}
        //   values="0,0,0,0,0,0"
        //   style={{ marginBottom: '-2px' }}
        // />
      }
      {!loading && (
        // <Sparkline
        //   options={{
        //     type: 'line',
        //     width: '100%',
        //     height: '75px',
        //     lineColor: color,
        //     chartRangeMin: '0',
        //     fillColor: color,
        //     spotColor: color,
        //     minSpotColor: color,
        //     maxSpotColor: color,
        //     highlightSpotColor: color,
        //     highlightLineColor: color,
        //     resize: true,
        //   }}
        //   values={fixedData}
        //   style={{ marginBottom: '-2px' }}
        // />
        <div>
          <Sparklines data={fixedData} margin={-2}>
            <svg>
              <defs>
                <LinearGradientFill
                  startColor={startColor || color}
                  endColor={endColor || color}
                  id={event}
                />
              </defs>
            </svg>
            <SparklinesLine
              color={color}
              style={{
                strokeWidth: 1,
                fill: `url(#${event})`,
              }}
            />
          </Sparklines>
        </div>
      )}
      {!loading && fixedData.length === 0 && (
        <div>
          <Sparklines data={[0, 0, 0, 0]} margin={-2}>
            <svg>
              <defs>
                <LinearGradientFill
                  startColor={startColor || color}
                  endColor={endColor || color}
                  id={event}
                />
              </defs>
            </svg>
            <SparklinesLine
              color={color}
              style={{
                strokeWidth: 1,
                fill: `url(#${event})`,
              }}
            />
          </Sparklines>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerId: state.customer.id,
  customerName: state.customer.name,
});
const connectedStoryJournalGraph = connect(mapStateToProps)(StoryJournalGraph);

export default withTranslation('translations')(connectedStoryJournalGraph);
