import gql from 'graphql-tag';

export const USER_STORY_CONTACTS_QUERY = gql`
  query storyContacts(
    $filter: V3_Customer_StoryContactFilter!
    $limit: Int = 25
    $skip: Int = 0
    $order: V3_Customer_StoryContactOrder = {
      priority: ASC
      personalizationScore: DESC
    }
  ) {
    v3_Customer_StoryContacts(
      where: $filter
      limit: $limit
      skip: $skip
      order: $order
    ) {
      id
      updatedAt
      account {
        id
        nameValue
        salesforceIdsValue
      }
      contact {
        id
        givenNameValue
        familyNameValue
        salesforceIdsValue
        lastApproachedAt
        lastContactedAt
        salesforceLastActivityDate {
          dateValue
        }
        position {
          email
          title
        }
      }
      status
      approved
      sender {
        fullName
      }
      story {
        id
        name
      }
      senderId
      priority
      personalizationScore
      emailContent
      emailPreview
      lastViewedAt
    }
    _v3_Customer_StoryContactsMeta(where: $filter) {
      count
    }
  }
`;

export const USER_STORY_CONTACT_COUNTS = gql`
  query storyContactCounts($filter: V3_Customer_StoryContactFilter!) {
    _v3_Customer_StoryContactsMeta(where: $filter) {
      count
    }
  }
`;

export const USER_STORY_CONTACT_MESSAGES_QUERY = gql`
  query storyContact($id: ID!) {
    v3_Customer_StoryContact(where: { id: $id }) {
      id
      emailContent
      elementToMessageLookup
      lastViewedAt
      elements {
        id
        triggerType
        text
        senderId
        triggerDataPoints {
          id
          value
          dataPointType
        }
        plotPointAsAdditional {
          id
          name
          position
          createdAt
          message {
            id
            name
          }
        }
        plotPointAsDefault {
          id
          name
          position
          createdAt
          message {
            id
            name
          }
        }
      }
    }
  }
`;
