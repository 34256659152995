import gql from 'graphql-tag';

export const UPDATE_PLOTPOINT = gql`
  mutation UpdatePlotPoint(
    $id: ID!
    $name: String!
    $position: Int!
    $plotPointCategoryId: String!
  ) {
    updateV3_Customer_StoryComponents_PlotPoint(
      id: $id
      data: {
        name: $name
        position: $position
        plotPointCategoryId: $plotPointCategoryId
      }
    ) {
      id
    }
  }
`;
