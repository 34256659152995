import React, { useEffect } from 'react';
import {
  REFRESH_STORY_CONTACT,
  USER_STORY_CONTACT_MESSAGES_QUERY,
} from '@koncert/graphql';
import EmailMessages from './EmailMessages';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

export const ExpandedRow = ({ customerId, currentUser, userLoading, row }) => {
  const [
    refreshStoryContactMutation,
    { loading: refreshLoading },
  ] = useMutation(REFRESH_STORY_CONTACT);

  const refreshStoryContact = () => {
    return refreshStoryContactMutation({
      variables: { id: row.original.id },
      refetchQueries: [
        {
          query: USER_STORY_CONTACT_MESSAGES_QUERY,
          variables: { id: row.original.id },
        },
      ],
    });
  };

  const [myQueryExecutor, { data, loading, error }] = useLazyQuery(
    USER_STORY_CONTACT_MESSAGES_QUERY,
    {
      variables: { id: row.original.id },
      skip: refreshLoading,
    }
  );

  useEffect(() => {
    myQueryExecutor();
  }, [myQueryExecutor]);

  if (loading || refreshLoading)
    return <i className="fa fa-spin fa-spinner"></i>;

  if (error) return <i className="fa fa-exclamation-triangle text-danger"></i>;

  const storyContact = data?.v3_Customer_StoryContact || {};

  return (
    <>
      {/* {refreshLoading && <i className="fa fa-spin fa-spinner"></i>}
      {!refreshLoading && (
        <Button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            refreshStoryContact(row.original.id);
            return false;
          }}
        >
          <i className="fa fa-refresh"></i> Refresh Contact
        </Button>
      )} */}
      {storyContact && (
        // <pre>{JSON.stringify(storyContact)}</pre>
        <EmailMessages
          customerId={customerId}
          currentUser={currentUser}
          userLoading={userLoading}
          row={row}
          emailContent={storyContact.emailContent}
          storyId={row.original.story.id}
          elements={storyContact.elements}
          elementToMessageLookup={storyContact.elementToMessageLookup}
          refreshMessages={refreshStoryContact}
        />
      )}
    </>
  );
};
