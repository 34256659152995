// GLOBAL CONSTANTS
// -----------------------------------

export const APP_COLORS = {
  primary: '#5d9cec',
  success: '#27c24c',
  info: '#23b7e5',
  warning: '#ff902b',
  danger: '#f05050',
  inverse: '#131e26',
  green: '#37bc9b',
  pink: '#f532e5',
  purple: '#7266ba',
  dark: '#3a3f51',
  yellow: '#fad732',
  'gray-darker': '#232735',
  'gray-dark': '#3a3f51',
  gray: '#dde6e9',
  'gray-light': '#e4eaec',
  'gray-lighter': '#edf1f2',
};

export const APP_MEDIAQUERY = {
  desktopLG: 1200,
  desktop: 992,
  tablet: 768,
  mobile: 480,
};

export const BadgeStatus = {
  Approaching: 'badge-danger bg-gradient-blood-orange-light',
  Contacted: 'badge-warning bg-gradient-purple-rain',
  Engaged: 'badge-info bg-gradient-turquoise-dream',
  Replied: 'badge-success bg-color-grass',
  New: 'badge-warning bg-gradient-baltic-sea',
  Error: 'badge-danger',
  'Error syncing': 'badge-danger',
  'Error in the sequencer': 'badge-danger',
  'ROE violation Error': 'badge-danger',
  Bounced: 'badge-danger',
  Referral: 'badge-danger',
  'Appointment booked': 'badge-success',
  'Opportunity created': 'badge-success',
  'Waiting to import': 'badge-info',
  Importing: 'badge-primary',
  'Import Complete': 'badge-success',
  'Import Partial Complete': 'badge-warning',
  Paused: 'badge-warning',
  'Error Importing': 'badge-danger',
};

export const Industries = [
  "(Holding company)",
"3D Face",
"3D Printers",
"3D Printing",
"3G",
"4G Fingervein Station",
"4G V-Station",
"4Projects",
"8D Company Building Program",
"APB Reloaded",
"API Gateway",
"ASAPer",
"Aakash tablet",
"Accessori e moda",
"Accessories",
"Accessories and Perfumes",
"Accident and Health",
"Accounting",
"Accounting & Accounting Services",
"Accounting Services",
"Accounting software",
"Accounts Receivable Management",
"Action figures",
"Actuators",
"Ad-Tech",
"Adaptive Optics",
"Administration publique",
"Administration scolaire et universitaire",
"Adsorbents",
"Advertising",
"Advertising & Marketing & Digital",
"Advertising / Marketing",
"Advertising technology",
"Advice",
"Advocacy",
"Aerospace",
"Aerospace & Defense",
"Affaires étrangères",
"Aggregates, Concrete & Cement",
"Agricultural Biologicals / Biostimulants",
"Agricultural Products",
"Agriculture",
"Agriculture & Forestry",
"Agriculture General",
"Agro-alimentaire",
"Air Freight & Logistics",
"Aircraft maintenance",
"Airlines",
"Airlines, Airports & Air Services",
"Airlines/Aviation",
"Airports",
"Alarm services for business customers and consumers",
"Aldurazyme",
"All Weather",
"Alprolix",
"Alternative Dispute Resolution",
"Alternative Investment Management",
"Alternative Medicine",
"American cuisine",
"Ammunition",
"Amusement Parks, Arcades & Attractions",
"Analytics",
"Android",
"Animals & Livestock",
"Animation",
"Annata IDMS",
"Annuities",
"Anti-piracy",
"Apartments",
"App Stores",
"Apparel",
"Apparel & Accessories Retail",
"Apparel & Fashion",
"Apparel & Footwear",
"Apparel & Home",
"Apparel, Accessories & Luxury Goods",
"Appliances",
"Application Processor",
"Application lifecycle management",
"Application performance management",
"Appy",
"Aptech Computer Education",
"Architecture",
"Architecture & Engineering",
"Architecture & Planning",
"Architecture et urbanisme",
"Architecture, Engineering & Design",
"Arena Animation",
"Arkitektur och planering",
"Art Gallery",
"Articles de luxe et bijouterie",
"Articles de sport",
"Arts",
"Arts & Crafts",
"Arts and Crafts",
"Arts et artisanat",
"Asigra Cloud Backup",
"Asset Management",
"Asset Management & Custody Banks",
"Associations et organisations sociales et syndicales",
"Assurance services",
"Assurances",
"Astro Ranch",
"Atego ApexAda Developer",
"Atego Asset Library",
"Atego Process Director",
"Atendimento ao consumidor",
"Auctions",
"Audience measurement",
"Audio",
"Audiobooks",
"Auto Components",
"Automation",
"Automatismes industriels",
"Automobile Parts Stores",
"Automobile financing",
"Automobiles",
"Automobiles & Components",
"Automotive",
"Automotive Bearings",
"Automotive Manufacturing",
"Automotive Service & Collision Repair",
"Automotive industry",
"Automotive sales and services",
"Automotive systems",
"Aviation & Aerospace",
"Aviation and aerospace insurance and reinsurance",
"Avocats",
"Avonex",
"B2B",
"B2C",
"BPO & service transformation",
"Back Office Solutions",
"Back office administration",
"Banking",
"Banking & Mortgages",
"Banks",
"Banques",
"Beechcraft",
"Beverages",
"Big Data",
"Big Data Analytics",
"Big Data Analytics and Petascale Storage solutions",
"Biotechnology",
"BlackBerry",
"Boating shoes",
"Boats & Submarines",
"Brands",
"Broadband",
"Broadcast Media",
"Broadcasting",
"Brokerage",
"Building Materials",
"Building Products",
"Business Intelligence",
"Business Services",
"Business Services General",
"Business Supplies",
"Business Supplies & Equipment",
"Business Supplies and Equipment",
"CPUs",
"Call Centers & Business Centers",
"Capital Goods",
"Capital Markets",
"Car & Truck Rental",
"Car Jack Streets",
"Cars",
"Casinos & Gambling",
"Casinos & Gaming",
"Catalysts",
"Charitable Organizations & Foundations",
"Chemicals",
"Chemicals, Petrochemicals, Glass & Gases",
"Chimie",
"Chipsets",
"Chrysler",
"Civic & Social Organization",
"Civil Engineering",
"Clothing",
"Cloud Data Services",
"Cloud Services",
"Commerce de détail",
"Commercial & Professional Services",
"Commercial & Residential Construction",
"Commercial Printing",
"Commercial Real Estate",
"Commercial Services & Supplies",
"Commercial Vehicles",
"Commodity Chemicals",
"Communications",
"Communications Equipment",
"Communities",
"Community Manager",
"Compagnie aérienne/Aviation",
"Comptabilité",
"Computer & Network Security",
"Computer Equipment & Peripherals",
"Computer Games",
"Computer Hardware",
"Computer Networking",
"Computer Peripherals",
"Computer Software",
"Computer and video games",
"Computers",
"Confection et mode",
"Conseil en management",
"Construction",
"Construction & Engineering",
"Construction Contractors & Services",
"Construction General",
"Construction Management",
"Construction Materials",
"Consulting",
"Consulting & Professional Services",
"Consumer Discretionary",
"Consumer Durables & Apparel",
"Consumer Electronics",
"Consumer Finance",
"Consumer Goods",
"Consumer Products",
"Consumer Services",
"Consumer Staples",
"Consumer credit",
"Consumer engineering",
"Contabilidad",
"Containers & Packaging",
"Contractors",
"Controls",
"Controls and Actuators design and manufacturing",
"Corporate & Business",
"Cosmetics",
"Cosmetics, Beauty Supply & Personal Care Products",
"Cosmétiques",
"Credit Cards & Transaction Processing",
"Crops",
"Curated Web",
"Customer Relationship Management",
"Customer service",
"Customized MEMS products and standardized Deformable mirrors",
"Dairy",
"Data Mining",
"Debt Collection",
"Defense",
"Defense & Aerospace",
"Defense & Space",
"Department Stores",
"Department Stores, Shopping Centers & Superstores",
"Design",
"Detaljhandel",
"Dhingana for iphone",
"Distributors",
"Diversified Capital Markets",
"Diversified Consumer Services",
"Diversified Financial Services",
"Diversified Financials",
"Diversified Telecommunication Services",
"Dodge",
"Drug Manufacturing & Research",
"Dungeons & Dragons Online",
"Défense et espace",
"E-Commerce & Marketplaces",
"E-commerce",
"E-learning",
"Education",
"Education General",
"Education Management",
"Education Services",
"Electric Utilities",
"Electrical",
"Electrical & Electronic Manufacturing",
"Electrical Equipment",
"Electricity, Oil & Gas",
"Electronic Components",
"Electronic Document Management",
"Electronic Equipment, Instruments & Components",
"Electronics",
"Eloctate",
"Emballages et conteneurs",
"Emerging Markets",
"Energy",
"Energy / Utilities",
"Energy Equipment & Services",
"Energy, Utilities & Waste Treatment",
"Engine manufacturing",
"Engineering",
"Engines",
"Enterprise",
"Entertainment",
"Entertainment & Recreation",
"Environmental Services",
"Events",
"Events Services",
"Executive Office",
"Eyewear",
"Facilities",
"Facilities Management & Commercial Cleaning",
"Facilities Services",
"Family Services",
"Farming",
"Fashion accessories",
"Federal",
"Film/Video Production & Services",
"Films d’animation",
"Finance",
"Finance General",
"Financial Services",
"Fine Art",
"Firearms",
"Fishery",
"Flight Controllers",
"Flowers, Gifts & Specialty Stores",
"Food",
"Food & Beverages",
"Food Processing",
"Food Production",
"Food Products",
"Food, Beverage & Tobacco",
"Food, Beverages & Tobacco",
"Footwear",
"Freelance",
"Freight & Logistics Services",
"Frooti",
"Fund of Funds",
"Fund-Raising",
"Fundraising",
"Funpark Friends",
"Furniture",
"Företagsrådgivning",
"Gambling & Casinos",
"Gaming Software/Systems",
"Gas Stations, Convenience & Liquor Stores",
"Gas Utilities",
"General aviation and military aircraft",
"Glass, Ceramics & Concrete",
"Gold",
"Government",
"Government Administration",
"Government Relations",
"Graphic Design",
"Grocery Retail",
"Ground Transportation",
"Hair Salons",
"Hardware",
"Health & Wellness",
"Health Care",
"Health Care Equipment & Services",
"Health Care Equipment & Supplies",
"Health Care Providers & Services",
"Health Care Services",
"Health Insurance",
"Health and Wellness",
"Health, Wellness & Fitness",
"Health, Wellness and Fitness",
"Healthcare",
"Healthcare General",
"High Performance Computing",
"Higher Education",
"Holding Companies",
"Home & Furniture",
"Home Improvement",
"Home Improvement & Hardware Retail",
"Homefurnishing Retail",
"Homeware",
"Hospital & Health Care",
"Hospitality",
"Hospitality General",
"Hospitals / Health Care",
"Hotels",
"Hotels, Restaurants & Leisure",
"Household & Personal Products",
"Household Durables",
"Household Goods",
"Household Products",
"Housewares",
"Human Resource & Employment Services",
"Human Resources",
"Human Resources & Staffing",
"Hôtellerie et hébergement",
"ISP",
"IT",
"IT Services",
"Import & Export",
"Import and Export",
"Independent Power and Renewable Electricity Producers",
"Individual & Family Services",
"Industrial",
"Industrial Automation",
"Industrial Bearings",
"Industrial Conglomerates",
"Industrial Machinery & Equipment",
"Industrials & Manufacturing",
"Industrie textile",
"Information & Document Management",
"Information Collection & Delivery",
"Information Services",
"Information Technology & Services",
"Information Technology and Services",
"Insurance",
"Integrated Telecommunication Services",
"Interactive entertainment",
"International Affairs",
"International Relations",
"International Trade",
"International Trade & Development",
"International Trade and Development",
"Internet",
"Internet & Catalog Retail",
"Internet Marketing",
"Internet Software & Services",
"Internet services",
"Investment",
"Investment Banking",
"Investment Banking & Brokerage",
"Investment Banking/Venture",
"Investment Management",
"Investment products",
"Jeep",
"Jewelery",
"Jewellery",
"Jewelry, Watches & Luxury Goods",
"Justin Time",
"Kids Apparel",
"Kuvan",
"LMN",
"Law Enforcement",
"Law Firms & Legal Services",
"Law Practice",
"Legal Services",
"Legislative Office",
"Leisure",
"Leisure & Travel",
"Leisure Facilities",
"Leisure Products",
"Leisure, Travel & Tourism",
"Libraries",
"Life Sciences",
"Life Sciences Tools & Services",
"Life insurance",
"Lifecycle Manager",
"Local",
"Lodging",
"Lodging & Resorts",
"Logiciels informatiques",
"Logistics",
"Logistics & Supply Chain",
"Logistics / Transportation",
"Logistics and Supply Chain",
"Lumber, Wood Production & Timber Operations",
"Luxury Goods & Jewelry",
"MEMS",
"Machinery",
"Management Consulting",
"Manufacturing",
"Manufacturing - Durables",
"Manufacturing - Non-Durables",
"Manufacturing General",
"Marine",
"Maritime",
"Market Research",
"Marketing",
"Marketing & Advertising",
"Marketing and Advertising",
"Marketing et publicité",
"Marketplace",
"Marketplaces",
"Marknadsföring och reklam",
"Materials",
"Mechanical Engineering",
"Mechanical or Industrial Engineering",
"Media",
"Media & Internet",
"Media Production",
"Medical Device",
"Medical Devices",
"Medical Devices & Equipment",
"Medical Practice",
"Medicine",
"Mental Health Care",
"Metals",
"Metals & Minerals",
"Metals & Mining",
"Military",
"Military vehicles",
"Minerals & Mining",
"Mining",
"Mining & Metals",
"Miscellaneous Building Materials (Flooring, Cabinets, etc.)",
"Missile and propulsion systems",
"Mobile",
"Mobile Advertising",
"Motion Pictures",
"Motion Pictures & Film",
"Motion Pictures and Film",
"Motor Vehicle Dealers",
"Motor Vehicle Parts",
"Motor Vehicles",
"Movies & Entertainment",
"Multiline Retail",
"Multimedia & Graphic Design",
"Museums",
"Museums and Institutions",
"Music",
"Music & Music Related Services",
"Musées et institutions culturelles",
"Médias en ligne",
"Naglazyme",
"Nanotechnology",
"Natural Gas",
"Networking",
"News",
"Newspapers",
"Newspapers & News Services",
"Non-Profit",
"Non-Profit & Philanthropy",
"Nonprofit Organization Management",
"Nuclear power",
"Office Products Retail & Distribution",
"Offices",
"Oil & Energy",
"Oil & Gas",
"Oil, Gas & Consumable Fuels",
"Olja och energi",
"Online Media",
"Online Publishing",
"Online and computer-based examinations",
"Outsourcing",
"Outsourcing Services",
"Outsourcing/Offshoring",
"Package/Freight Delivery",
"Packaged Foods & Meats",
"Packaging & Containers",
"Packaging and Containers",
"Palamida Enterprise Edition 6",
"Paper & Forest Products",
"Paper Goods",
"Paper Products",
"Parenting",
"Payments",
"Performing Arts",
"Perfume",
"Personal Computers & Peripherals",
"Personal Products",
"Personal och rekrytering",
"Personalwesen",
"Pet Products",
"Pets",
"Pharmaceuticals",
"Pharmaceuticals, Biotechnology & Life Sciences",
"Philanthropy",
"Photographic & Optical Equipment",
"Photography",
"Photography Platforms",
"Planning",
"Plastic, Packaging & Containers",
"Plastics",
"Plastiques",
"Plug & Protect",
"Plumbing & HVAC Equipment",
"PocketSurfer",
"Political Organization",
"Power Conversion & Protection Equipment",
"Primary/Secondary Education",
"Print & Digital Media",
"Print Media",
"Printing",
"Private banking",
"Process technology",
"Production audiovisuelle",
"Products Fixed line and mobile telephony",
"Professional Services",
"Professional Sports",
"Professional Training",
"Professional Training & Coaching",
"Program Development",
"Programmatic Ad Buying",
"Property and Casualty",
"Public Policy",
"Public Relations",
"Public Relations and Communications",
"Public Safety",
"Publishing",
"Pulp & Paper",
"Pure Alpha",
"Pure Alpha Major Markets",
"Quality and luxury goods",
"Racquets",
"Railroad Manufacture",
"Railways Bearings",
"Ranching",
"Real Estate",
"Real Estate / Property Management",
"Real Estate Advisory",
"Real Estate Investment Trusts",
"Real Estate Management & Development",
"Recreation",
"Recreational Facilities and Services",
"Recruiting",
"Religion",
"Religious Organizations",
"Renewable Electricity",
"Renewable Energy",
"Renewables & Environment",
"Research",
"Research & Consulting Services",
"Resources",
"Restaurants",
"Retail",
"Retail General",
"Retailing",
"Riskkapital",
"Road & Rail",
"SAAS",
"SRAX and GroupAd",
"School Districts",
"Scientific & Academic Research",
"Scientific Instruments",
"Search Engines & Internet Portals",
"Security",
"Security & Investigations",
"Security Products & Services",
"Security and Investigations",
"Semiconductor & Semiconductor Equipment",
"Semiconductors",
"Services",
"Services financiers",
"Shipbuilding",
"Shipping & Logistics",
"Shirts",
"Shoes",
"Shopping Malls",
"Shorts",
"Skeppsbyggnad",
"SoCs",
"Social Bookmarking",
"Social Media",
"Social marketing technology",
"Society",
"Software",
"Software & Services",
"Software & Technical Consulting",
"Software Development & Design",
"Speciality Retail",
"Specialized Consumer Services",
"Specialty Retail",
"Sporting Goods",
"Sports",
"Sports & Fitness",
"Sports equipment",
"Staffing & Recruiting",
"Staffing and Recruiting",
"Startups",
"State",
"Subcontrataciones/Offshoring",
"Supermarkets",
"Supply Chain and Logistics",
"Sweaters",
"Swimwear",
"Talent Agencies",
"Tax advisory",
"Technologies et services de l’information",
"Technology",
"Technology Hardware & Equipment",
"Technology Hardware, Storage & Peripherals",
"Telecom / Communication Services",
"Telecommunication Services",
"Telecommunications",
"Telecommunications General",
"Telephony & Wireless",
"Television Series",
"Television Stations",
"Test & Measurement Equipment",
"Tex-Mex cuisine",
"Textiles",
"Textiles & Apparel",
"Textiles, Apparel & Luxury Goods",
"The Lord of the Rings Online",
"Think Tanks",
"Tobacco",
"Toys",
"Toys & Games",
"Trading Companies & Distributors",
"Training",
"Transaction Consulting",
"Translation",
"Translation and Localization",
"Transportation",
"Transportation General",
"Transportation/Trucking/Railroad",
"Travel & Leisure",
"Travel & Tourism",
"Travel Agencies & Services",
"Traveller",
"Trax",
"Trucks",
"Trump",
"Turboshaft and jet engines",
"Ubislate 7",
"Utilities",
"VTRUX Suv",
"VTRUX Van",
"Valuation",
"Venture Capital",
"Venture Capital & Private Equity",
"Veterinary",
"Video",
"Video games",
"Video games (casual games)",
"Warehousing",
"Waste Management",
"Waste Treatment, Environmental Services & Recycling",
"Wealth management",
"Web Services & Apps",
"Whole and Universal Life",
"Wholesale",
"Wine & Spirits",
"Wine and Spirits",
"Wire & Cable",
"Wireless",
"Womenswear",
"Writing & Editing",
"Writing and Editing",
"advisory services",
"and Disability Insurance",
"casualty insurance and reinsurance",
"distribution and marketing",
"eCommerce",
"energy insurance",
"media-company",
"motherboards",
"online piracy tracking",
"paperboards & specialty papers",
"peer-to-peer",
"yellow-pages"
];

export const Titles = [
  "AVP",
"Account Coordinator",
"Account Director",
"Account Executive",
"Account Manager",
"Account Representative",
"Account Supervisor",
"Accountant",
"Accounting Manager",
"Accounting Supervisor",
"Adjunct Faculty",
"Adjunct Instructor",
"Adjunct Professor",
"Administrative Assistant",
"Administrator",
"Advertising Manager",
"Advisor",
"Advisory Board",
"Advisory Board Member",
"Agent",
"Analyst",
"Angel Investor",
"Application Developer",
"Application Engineer",
"Applications Engineer",
"Architect",
"Area Manager",
"Area Sales Manager",
"Art Director",
"Assistant",
"Assistant Account Executive",
"Assistant Brand Manager",
"Assistant Buyer",
"Assistant Controller",
"Assistant Director",
"Assistant Editor",
"Assistant General Counsel",
"Assistant General Manager",
"Assistant Manager",
"Assistant Marketing Manager",
"Assistant Professor",
"Assistant Store Manager",
"Assistant Treasurer",
"Assistant Vice President",
"Associate",
"Associate Attorney",
"Associate Brand Manager",
"Associate Consultant",
"Associate Creative Director",
"Associate Director",
"Associate Editor",
"Associate General Counsel",
"Associate Marketing Manager",
"Associate Partner",
"Associate Producer",
"Associate Product Manager",
"Attorney",
"Audit Manager",
"Audit Senior",
"Auditor",
"Author",
"Barista",
"Bartender",
"Benefits Manager",
"Blogger",
"Board Advisor",
"Board Director",
"Board Member",
"Board Observer",
"Board Of Directors",
"Board member",
"Board of Advisors",
"Board of Directors",
"Branch Manager",
"Brand Ambassador",
"Brand Manager",
"Business Analyst",
"Business Consultant",
"Business Development",
"Business Development Associate",
"Business Development Consultant",
"Business Development Director",
"Business Development Executive",
"Business Development Intern",
"Business Development Manager",
"Business Development Representative",
"Business Intelligence Analyst",
"Business Manager",
"Business Owner",
"Business Systems Analyst",
"Buyer",
"CEO",
"CEO & Co-Founder",
"CEO & Founder",
"CEO & President",
"CEO and Co-Founder",
"CEO and Founder",
"CEO, Co-founder",
"CEO, Founder",
"CEO/Founder",
"CFO",
"CIO",
"CMO",
"COO",
"CPA",
"CTO",
"Campaign Manager",
"Captain",
"Cashier",
"Category Manager",
"Chairman",
"Chairman & CEO",
"Chairman and CEO",
"Chairman of the Board",
"Channel Marketing Manager",
"Chief Architect",
"Chief Commercial Officer",
"Chief Executive",
"Chief Executive Officer",
"Chief Executive Officer & President",
"Chief Financial Officer",
"Chief Human Resources Officer",
"Chief Information Officer",
"Chief Information Security Officer",
"Chief Innovation Officer",
"Chief Marketing Officer",
"Chief Medical Officer",
"Chief Operating Officer",
"Chief Operations Officer",
"Chief People Officer",
"Chief Product Officer",
"Chief Revenue Officer",
"Chief Strategy Officer",
"Chief Technology Officer",
"Chief of Staff",
"Client Services Manager",
"Co Founder",
"Co Owner",
"Co-Founder",
"Co-Founder & CEO",
"Co-Founder & CTO",
"Co-Founder and CEO",
"Co-Founder and CTO",
"Co-Founder, CEO",
"Co-Owner",
"Co-founder",
"Co-founder & CEO",
"Co-founder and CEO",
"Co-founder, CEO",
"Coach",
"Cofounder",
"Commercial Director",
"Communications Coordinator",
"Communications Director",
"Communications Intern",
"Communications Manager",
"Communications Specialist",
"Community Manager",
"Consultant",
"Content Manager",
"Content Marketing Manager",
"Contract Recruiter",
"Contractor",
"Contributing Writer",
"Contributor",
"Controller",
"Coordinator",
"Copywriter",
"Corporate Controller",
"Corporate Recruiter",
"Creative Director",
"Credit Analyst",
"Credit Manager",
"Customer Service",
"Customer Service Manager",
"Customer Service Representative",
"Customer Success Manager",
"Data Analyst",
"Data Architect",
"Data Scientist",
"Database Administrator",
"Design Director",
"Design Engineer",
"Design Intern",
"Designer",
"DevOps Engineer",
"Developer",
"Development Director",
"Development Manager",
"Digital Marketing Consultant",
"Digital Marketing Intern",
"Digital Marketing Manager",
"Digital Marketing Specialist",
"Digital Strategist",
"Director",
"Director Business Development",
"Director Human Resources",
"Director Of Operations",
"Director Product Management",
"Director of Accounting",
"Director of Business Development",
"Director of Client Services",
"Director of Communications",
"Director of Development",
"Director of Digital Marketing",
"Director of Engineering",
"Director of Finance",
"Director of HR",
"Director of Human Resources",
"Director of IT",
"Director of Information Technology",
"Director of Marketing",
"Director of Marketing Communications",
"Director of Marketing and Communications",
"Director of Operations",
"Director of Product",
"Director of Product Development",
"Director of Product Management",
"Director of Product Marketing",
"Director of Public Relations",
"Director of Purchasing",
"Director of Recruiting",
"Director of Sales",
"Director of Sales & Marketing",
"Director of Sales Operations",
"Director of Sales and Marketing",
"Director of Software Development",
"Director of Software Engineering",
"Director of Technology",
"Director, Accounting",
"Director, Business Development",
"Director, Client Services",
"Director, Communications",
"Director, Digital Marketing",
"Director, Engineering",
"Director, Finance",
"Director, Financial Planning & Analysis",
"Director, Human Resources",
"Director, Information Technology",
"Director, Marketing",
"Director, Marketing & Communications",
"Director, Operations",
"Director, Product Development",
"Director, Product Management",
"Director, Product Marketing",
"Director, Purchasing",
"Director, Sales",
"Director, Sales Operations",
"District Manager",
"District Sales Manager",
"Division Controller",
"EA",
"EVP",
"EVP, CFO",
"Editor",
"Editorial Assistant",
"Editorial Intern",
"Email Marketing Manager",
"Engagement Manager",
"Engineer",
"Engineering Intern",
"Engineering Manager",
"English Teacher",
"Enterprise Account Executive",
"Enterprise Account Manager",
"Enterprise Architect",
"Entrepreneur",
"Entrepreneur in Residence",
"Event Coordinator",
"Event Manager",
"Event Planner",
"Executive",
"Executive Assistant",
"Executive Assistant to CEO",
"Executive Creative Director",
"Executive Director",
"Executive Producer",
"Executive Recruiter",
"Executive VP & Chief Financial Officer",
"Executive Vice President",
"Fellow",
"Field Marketing Manager",
"Finance",
"Finance Director",
"Finance Intern",
"Finance Manager",
"Financial Advisor",
"Financial Analyst",
"Financial Consultant",
"Financial Controller",
"Founder",
"Founder & CEO",
"Founder & Chief Executive Officer",
"Founder & President",
"Founder / CEO",
"Founder and CEO",
"Founder and President",
"Founder, CEO",
"Founder/CEO",
"Founding Partner",
"Freelance Designer",
"Freelance Graphic Designer",
"Freelance Writer",
"Freelancer",
"General Counsel",
"General Manager",
"General Partner",
"General Sales Manager",
"Global Account Manager",
"Graduate Assistant",
"Graduate Research Assistant",
"Graduate Student",
"Graduate Teaching Assistant",
"Graphic Design Intern",
"Graphic Designer",
"Group Account Director",
"Group Product Manager",
"HR",
"HR Assistant",
"HR Business Partner",
"HR Consultant",
"HR Coordinator",
"HR Director",
"HR Generalist",
"HR Manager",
"HR Specialist",
"Head of Business Development",
"Head of Marketing",
"Head of Product",
"Head of Sales",
"Human Resource Manager",
"Human Resources",
"Human Resources Assistant",
"Human Resources Business Partner",
"Human Resources Consultant",
"Human Resources Coordinator",
"Human Resources Director",
"Human Resources Generalist",
"Human Resources Intern",
"Human Resources Manager",
"Human Resources Representative",
"Human Resources Specialist",
"IT Consultant",
"IT Director",
"IT Manager",
"IT Project Manager",
"IT Specialist",
"Independent Consultant",
"Independent Contractor",
"Information Technology Manager",
"Inside Sales",
"Inside Sales Manager",
"Inside Sales Representative",
"Instructor",
"Intern",
"Internal Auditor",
"Internship",
"Investment Analyst",
"Investment Banking Analyst",
"Investor",
"Java Developer",
"Key Account Manager",
"Law Clerk",
"Lead Developer",
"Lead Engineer",
"Lead Software Engineer",
"Lecturer",
"Legal Assistant",
"Legal Intern",
"Loan Officer",
"Management Consultant",
"Management Trainee",
"Manager",
"Manager, Accounting",
"Manager, Business Development",
"Manager, Finance",
"Manager, Human Resources",
"Manager, Marketing",
"Manager, Product Development",
"Manager, Product Marketing",
"Manager, Purchasing",
"Managing Consultant",
"Managing Director",
"Managing Editor",
"Managing Member",
"Managing Partner",
"Manufacturing Engineer",
"Market Manager",
"Market Research Analyst",
"Marketing",
"Marketing Analyst",
"Marketing Assistant",
"Marketing Associate",
"Marketing Communications Manager",
"Marketing Communications Specialist",
"Marketing Consultant",
"Marketing Coordinator",
"Marketing Director",
"Marketing Executive",
"Marketing Intern",
"Marketing Manager",
"Marketing Operations Manager",
"Marketing Programs Manager",
"Marketing Project Manager",
"Marketing Representative",
"Marketing Specialist",
"Marketing Strategist",
"Mechanical Engineer",
"Media Director",
"Media Planner",
"Media Supervisor",
"Member",
"Member Board Of Directors",
"Member Board of Directors",
"Member of Technical Staff",
"Member of the Board",
"Member of the Board of Directors",
"Member, Board of Directors",
"Mentor",
"National Account Executive",
"National Account Manager",
"National Sales Director",
"National Sales Manager",
"Network Administrator",
"Network Engineer",
"Network Manager",
"Non Executive Director",
"Office Administrator",
"Office Assistant",
"Office Manager",
"Online Marketing Manager",
"Operations",
"Operations Analyst",
"Operations Director",
"Operations Manager",
"Owner",
"Paralegal",
"Partner",
"Personal Banker",
"Photographer",
"Plant Controller",
"Plant Manager",
"Portfolio Manager",
"President",
"President & CEO",
"President & COO",
"President & Chief Executive Officer",
"President & Chief Operating Officer",
"President & Founder",
"President / CEO",
"President and CEO",
"President and COO",
"President and Chief Executive Officer",
"President and Founder",
"President, CEO",
"President, COO",
"President/CEO",
"President/Owner",
"Principal",
"Principal Architect",
"Principal Consultant",
"Principal Engineer",
"Principal Product Manager",
"Principal Software Engineer",
"Process Engineer",
"Producer",
"Product Designer",
"Product Development Manager",
"Product Director",
"Product Engineer",
"Product Line Manager",
"Product Management",
"Product Manager",
"Product Marketing",
"Product Marketing Manager",
"Product Owner",
"Product Specialist",
"Production Assistant",
"Production Coordinator",
"Production Intern",
"Production Manager",
"Professor",
"Program Coordinator",
"Program Director",
"Program Manager",
"Programmer",
"Programmer Analyst",
"Programmer/Analyst",
"Project Coordinator",
"Project Director",
"Project Engineer",
"Project Lead",
"Project Leader",
"Project Manager",
"Property Manager",
"Public Relations Intern",
"Public Relations Manager",
"Publisher",
"Purchasing Manager",
"QA Engineer",
"QA Manager",
"Quality Assurance Manager",
"Quality Manager",
"Realtor",
"Receptionist",
"Recruiter",
"Recruiting Coordinator",
"Recruiting Manager",
"Recruitment Consultant",
"Recruitment Manager",
"Regional Account Manager",
"Regional Director",
"Regional Manager",
"Regional Marketing Manager",
"Regional Sales Director",
"Regional Sales Manager",
"Regional Vice President",
"Relationship Manager",
"Reporter",
"Research Analyst",
"Research Assistant",
"Research Associate",
"Research Director",
"Research Engineer",
"Research Fellow",
"Research Intern",
"Research Manager",
"Research Scientist",
"Researcher",
"Resident Assistant",
"Retired",
"SVP",
"SVP, CFO",
"Sales",
"Sales & Marketing Manager",
"Sales Account Manager",
"Sales Assistant",
"Sales Associate",
"Sales Consultant",
"Sales Coordinator",
"Sales Development Manager",
"Sales Development Representative",
"Sales Director",
"Sales Engineer",
"Sales Executive",
"Sales Intern",
"Sales Manager",
"Sales Operations Manager",
"Sales Rep",
"Sales Representative",
"Sales and Marketing Manager",
"Scientist",
"Senior Account Director",
"Senior Account Executive",
"Senior Account Manager",
"Senior Accountant",
"Senior Advisor",
"Senior Analyst",
"Senior Architect",
"Senior Art Director",
"Senior Associate",
"Senior Auditor",
"Senior Brand Manager",
"Senior Business Analyst",
"Senior Business Development Manager",
"Senior Buyer",
"Senior Consultant",
"Senior Copywriter",
"Senior Designer",
"Senior Developer",
"Senior Director",
"Senior Director of Marketing",
"Senior Director, Product Management",
"Senior Editor",
"Senior Engineer",
"Senior Finance Manager",
"Senior Financial Analyst",
"Senior Graphic Designer",
"Senior Manager",
"Senior Marketing Manager",
"Senior Network Engineer",
"Senior Partner",
"Senior Producer",
"Senior Product Manager",
"Senior Product Marketing Manager",
"Senior Program Manager",
"Senior Programmer",
"Senior Project Manager",
"Senior Recruiter",
"Senior Sales Engineer",
"Senior Sales Executive",
"Senior Sales Manager",
"Senior Scientist",
"Senior Software Architect",
"Senior Software Developer",
"Senior Software Engineer",
"Senior Solutions Architect",
"Senior Systems Administrator",
"Senior Systems Analyst",
"Senior Systems Engineer",
"Senior Technical Recruiter",
"Senior VP",
"Senior VP & Chief Financial Officer",
"Senior Vice President",
"Senior Web Developer",
"Server",
"Service Manager",
"Social Media Coordinator",
"Social Media Intern",
"Social Media Manager",
"Social Media Marketing Manager",
"Social Media Specialist",
"Social Media Strategist",
"Software Architect",
"Software Consultant",
"Software Developer",
"Software Development Engineer",
"Software Development Manager",
"Software Engineer",
"Software Engineer Intern",
"Software Engineering Intern",
"Software Engineering Manager",
"Solution Architect",
"Solutions Architect",
"Speaker",
"Specialist",
"Sr Software Engineer",
"Sr. Account Executive",
"Sr. Account Manager",
"Sr. Business Analyst",
"Sr. Consultant",
"Sr. Financial Analyst",
"Sr. Manager",
"Sr. Marketing Manager",
"Sr. Product Manager",
"Sr. Product Marketing Manager",
"Sr. Project Manager",
"Sr. Recruiter",
"Sr. Software Engineer",
"Sr. Systems Engineer",
"Sr. Technical Recruiter",
"Sr. Vice President",
"Staff Accountant",
"Staff Engineer",
"Staff Software Engineer",
"Staff Writer",
"Staffing Consultant",
"Staffing Manager",
"Store Manager",
"Strategic Account Manager",
"Strategic Advisor",
"Strategy Consultant",
"Student",
"Student Assistant",
"Summer Analyst",
"Summer Associate",
"Summer Intern",
"Supervisor",
"Supply Chain Manager",
"System Administrator",
"System Analyst",
"System Engineer",
"Systems Administrator",
"Systems Analyst",
"Systems Architect",
"Systems Engineer",
"Talent Acquisition Manager",
"Talent Acquisition Specialist",
"Tax Manager",
"Teacher",
"Teaching Assistant",
"Team Lead",
"Team Leader",
"Technical Account Manager",
"Technical Architect",
"Technical Consultant",
"Technical Director",
"Technical Lead",
"Technical Manager",
"Technical Product Manager",
"Technical Project Manager",
"Technical Recruiter",
"Technical Support",
"Technical Support Engineer",
"Technical Writer",
"Technician",
"Technology Consultant",
"Teller",
"Territory Manager",
"Territory Sales Manager",
"Tour Manager",
"Trainee",
"Trainer",
"Training Manager",
"Treasurer",
"Trustee",
"Tutor",
"VP",
"VP Business Development",
"VP Engineering",
"VP Finance",
"VP HR",
"VP Human Resources",
"VP IT",
"VP Marketing",
"VP Operations",
"VP Product Management",
"VP Sales",
"VP Sales & Marketing",
"VP Sales and Marketing",
"VP of Business Development",
"VP of Engineering",
"VP of Finance",
"VP of Marketing",
"VP of Operations",
"VP of Sales",
"VP, Business Development",
"VP, Human Resources",
"VP, Marketing",
"Various",
"Venture Partner",
"Vice President",
"Vice President & Chief Financial Officer",
"Vice President Business Development",
"Vice President Finance",
"Vice President Human Resources",
"Vice President Marketing",
"Vice President Operations",
"Vice President Sales",
"Vice President Sales and Marketing",
"Vice President of Business Development",
"Vice President of Engineering",
"Vice President of Finance",
"Vice President of Human Resources",
"Vice President of Marketing",
"Vice President of Operations",
"Vice President of Sales",
"Vice President of Sales and Marketing",
"Vice President, Business Development",
"Vice President, Engineering",
"Vice President, Finance",
"Vice President, Human Resources",
"Vice President, Information Technology",
"Vice President, Marketing",
"Vice President, Operations",
"Vice President, Product Development",
"Vice President, Product Management",
"Vice President, Sales",
"Vice President, Sales & Marketing",
"Volunteer",
"Web Designer",
"Web Developer",
"Webmaster",
"Writer"
];

export const States = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'America Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Island',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const DataPointTypes = [
  'Address',
  'AlexaGlobalRank',
  'AlexaUsRank',
  // 'Avatar',
  'Bio',
  'BlogUrl',
  'BusinessModel',
  // 'ChattingWith',
  // 'CompanyLinkedinUrl',
  'CompanyName',
  // 'CrunchbaseHandle',
  'Description',
  'Domain',
  'Education',
  'Email',
  'EmailDomain',
  'EmployeeCountRange',
  'EmployeeGrowth',
  'ExperiencedIn',
  // 'FacebookHandle',
  'FamilyName',
  'FoundedBy',
  'FullName',
  'Gender',
  // 'GithubHandle',
  'GivenName',
  // 'GoogleRank',
  'HasADegreeIn',
  'HaveRaised',
  'HaveRaisedRange',
  'Industry',
  'InfluentialAbout',
  // 'Is',
  'Knows',
  // 'LegalName',
  // 'Like',
  // 'LinkedinUrl',
  'Location',
  'LocationGeneral',
  'MarketCap',
  'MemberOfTheGroup',
  'MentionedInNews',
  'NumberOfEmployees',
  'OfficeInCity',
  'OfficeInCountry',
  'OfficeInRegion',
  'OfficeInState',
  // 'OfficePhone',
  'Ownership',
  // 'Phone',
  'Position',
  'PreviousCompany',
  'PreviousTitle',
  'ReportsToName',
  'Revenue',
  'RevenueRange',
  'SalesGrowth',
  'Sector',
  'Seniority',
  'SfdcCustomValue',
  'Studied',
  'Tag',
  // 'TalkingAbout',
  'Tech',
  // 'Ticker',
  // 'TimeZone',
  'Title',
  // 'TwitterHandle',
  'UsesApp',
  'UsesService',
  // 'UtcOffset',
  // 'Wants',
  // 'WantsLocation',
  'Website',
  'WorkedInDepartment',
  'WorksAt',
  'WorksInDepartment',
  'WorksWith',
  // 'WroteAnArticle',
];

export const FACTS = [
  {
    value: 'addresses.state',
    label: 'State',
  },
  {
    value: 'number_of_employees',
    label: 'Number of Employees',
  },
];
